import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { getInfomationRequest } from "src/actions/infomationActions";
import { useDispatch } from "react-redux";

import "./index.css";
import ContactHomeView from "../HomeView/Contact";
import icon_phone from "../../../assets/images/icon_phone.png";
import icon_email from "../../../assets/images/icon_email.png";
import mapdetail from "../../../assets/images/mapdetail.png";

import MapContainer from "./MapContainer";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
  },
  rootMb: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 64,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    // background: url("../img/intro-bg.jpg"),
    height: 560,
    backgroundImage: `url(images/bgjob.png)`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
}));
function Contact() {
  const sendMail = () => {};
  const dispatch = useDispatch();
  const [dataContact, setDataContact] = useState("");

  const classes = useStyles();
  useStyles();
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    dispatch(
      getInfomationRequest(
        (res) => {
          setDataContact(res);
        },
        (error) => {
          console.log("error", error);
        }
      )
    );
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  return (
    <Page
      className={mobileView ? classes.rootMb : classes.root}
      title="Liên hệ"
    >
      {!mobileView ? (
        <div
          className="container "
          style={{
            marginTop: 58,
            marginBottom: 80,
            height: 507,
          }}
        >
          <div
            className="col-md-6"
            style={{
              padding: 0,
              paddingRight: 64,
              height: 507,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ flex: 1 }}>
              <div className="title-color ">THÔNG TIN LIÊN LẠC</div>
              <div className="title-contact-top">
                CÔNG TY CỔ PHẦN QUẢN LÝ BẤT ĐỘNG SẢN NAM SÀI GÒN
              </div>
              <div className="txt-address">
                <span style={{ fontWeight: 600 }}>Địa chỉ: </span>
                {dataContact.address}
              </div>
              {dataContact.office !== "" && dataContact.office !== undefined && (
                <div className="txt-address">
                  <span style={{ fontWeight: 600 }}>Văn phòng đại diện: </span>
                  {dataContact.office}
                </div>
              )}
            </div>
            <a href={`mailto:${dataContact.email}?body=My custom mail body`}>
              <div className="email-box">
                <img className="icon-email" src={icon_email} />
                <div>
                  <div className="title-email" style={{ color: "#313131" }}>
                    Email
                  </div>
                  <div className="title-email">{dataContact.email}</div>
                </div>
              </div>
            </a>

            <div className="email-box" style={{ marginTop: 16 }}>
              <img className="icon-email" src={icon_phone} />
              <div>
                <div className="title-email" style={{ color: "#313131" }}>
                  Hotline
                </div>
                <div className="title-email">{dataContact.hotline}</div>
              </div>
              <div style={{ marginLeft: 40 }}>
                <div className="title-email" style={{ color: "#313131" }}>
                  Điện thoại
                </div>
                <div className="title-email">{dataContact.phone}</div>
              </div>
            </div>
          </div>
          {!mobileView && (
            <div className="col-md-6" style={{ padding: 0, paddingLeft: 64 }}>
              <div className="map-view ">
                <div className="map-content">
                  {/* <MapContainer /> */}
                  <img
                    onClick={() => {
                      window.open(dataContact.linkGoogleMap, "_blank");
                    }}
                    className="hover-ct"
                    src={mapdetail}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      borderRadius: 8,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="container "
          style={{
            marginTop: 16,
            marginBottom: 32,
          }}
        >
          <div className="row">
            <div
              className="col-md-6"
              style={{
                height: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="title-color ">THÔNG TIN LIÊN LẠC</div>
                <div className="title-contact-top">
                  CÔNG TY CỔ PHẦN QUẢN LÝ BẤT ĐỘNG SẢN NAM SÀI GÒN
                </div>
                <div className="txt-address">
                  <span style={{ fontWeight: 600 }}>Địa chỉ:</span>
                  {dataContact.address}
                </div>
                {dataContact.office !== "" && (
                  <div className="txt-address">
                    <span style={{ fontWeight: 600 }}>Văn phòng đại diện:</span>
                    {dataContact.office}
                  </div>
                )}
              </div>
              <a href="mailto:info.sspmcorp@gmail.com?body=My custom mail body">
                <div className="email-box" style={{ marginTop: 40 }}>
                  <img className="icon-email" src={icon_email} />
                  <div style={{ textAlign: "center" }}>
                    <div className="title-email" style={{ color: "#313131" }}>
                      Email
                    </div>
                    <div className="title-email">info.sspmcorp@gmail.com</div>
                  </div>
                </div>
              </a>

              <div className="email-box" style={{ marginTop: 16 }}>
                <img className="icon-email" src={icon_phone} />
                <div style={{ textAlign: "center" }}>
                  <div className="title-email" style={{ color: "#313131" }}>
                    Hotline
                  </div>
                  <div className="title-email">{dataContact.hotline}</div>
                </div>
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <div className="title-email" style={{ color: "#313131" }}>
                    Điện thoại
                  </div>
                  <div className="title-email">{dataContact.phone}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6" style={{}}>
              <div className="map-view ">
                <div className="map-content">
                  <img
                    onClick={() => {
                      window.open(dataContact.linkGoogleMap, "_blank");
                    }}
                    className="hover-ct"
                    src={mapdetail}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      borderRadius: 8,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ContactHomeView mobileView={mobileView} />
    </Page>
  );
}

export default Contact;
