import jwtDecode from "jwt-decode";
import axios from "src/utils/axios";

class FeedbackService {
  create = (payload) =>
    new Promise((resolve, reject) => {
      console.log("payload", payload);
      axios
        .post("/feedback/create", payload)
        .then((response) => {
          console.log(response);
          if (response.data.message === 1) {
            resolve(response.data.post);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getList = () =>
    new Promise((resolve, reject) => {
      axios
        .get("/feedback/all")
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getDetail = (path) =>
    new Promise((resolve, reject) => {
      axios
        .get("/feedback/detail/" + path)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  editItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .put("/feedback/edit?itemID=" + payload.id, payload)
        .then((response) => {
          console.log("response", response);
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  deleteItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .delete("/feedback/delete?itemID=" + payload.id)
        .then((response) => {
          console.log("response", response);
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
}

const feedbackService = new FeedbackService();

export default feedbackService;
