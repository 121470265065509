import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./Highlights.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import { getListProjectHighLightHomeRequest } from "src/actions/projectActions";
import { getHighlightRequest } from "src/actions/highlightActions";

import { useDispatch } from "react-redux";
import button_next from "src/assets/images/button_next.png";
import arrow2 from "src/assets/images/arrow2.png";
import handleViewport from "react-in-viewport";
import Scrollspy from "react-scrollspy";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
}));

function Highlights({ className, mobileView, goToLink, ...rest }) {
  const classes = useStyles();
  // const [swiperRef, setSwiperRef] = useState(null);
  // let appendNumber = 600;
  // let prependNumber = 1;
  const [inViewTop, setInViewTop] = useState(false);
  const [inViewMiddle, setInViewMiddle] = useState(false);
  const [countTopView, setCountTopView] = useState(0);
  const [inViewBottom, setInViewBottom] = useState(false);
  const dispatch = useDispatch();
  const [listProjectHighlight, setListProjectHighlight] = useState([]);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    getListProjectHighLight();
  }, []);
  // const getListProjectHighLight = useCallback(() => {
  //   dispatch(
  //     getListProjectHighLightHomeRequest(
  //       (res) => {
  //         setListProjectHighlight(res);
  //       },
  //       (error) => {}
  //     )
  //   );
  // }, []);
  const getListProjectHighLight = useCallback(() => {
    dispatch(
      getHighlightRequest(
        (res) => {
          try {
            setListProjectHighlight(JSON.parse(res.value));
          } catch (error) {
            setListProjectHighlight([]);
          }
        },
        (error) => {}
      )
    );
  }, []);
  // const prepend = () => {
  //   swiperRef.virtual.prependSlide([
  //     "Slide " + --prependNumber,
  //     "Slide " + --prependNumber,
  //   ]);
  // };

  // const append = () => {
  //   swiperRef.virtual.appendSlide("Slide " + ++appendNumber);
  // };

  // const slideTo = (index) => {
  //   swiperRef.slideTo(index - 1, 0);
  // };

  // Create array with 1000 slides
  const slides = Array.from({ length: 10 }).map(
    (_, index) => `Slide ${index + 1}`
  );
  const Block = (props) => {
    const { inViewport, forwardedRef } = props;
    return <div ref={forwardedRef}></div>;
  };
  const Block1 = (props) => {
    const { inViewport, forwardedRef } = props;
    return <div ref={forwardedRef}></div>;
  };

  const ViewportBlock = handleViewport(Block);
  const ViewportBlock1 = handleViewport(Block1);
  return (
    <div className={"Highlights"}>
      {mobileView ? (
        <Scrollspy items={["section-1"]} currentClassName="isCurrentHigh">
          <div
            style={{
              paddingRight: 15,
              paddingLeft: 15,
              marginBottom: 15,
            }}
            className={"row-ct"}
          >
            <div
              className={" title"}
              style={{
                flex: 1,
              }}
            >
              Dự Án
            </div>
            <div
              onClick={() => {
                goToLink("/du-an");
              }}
            >
              <span className={"about-box-title"}>Tất cả dự án</span>
              <img className="arrow-see-more" style={{ marginRight: 0 }} />
            </div>
          </div>
        </Scrollspy>
      ) : (
        <div className="container" style={{ marginBottom: 15 }}>
          <div className="row-custom">
            <div className="title-home">Dự án</div>
            <div
              className="home-box-detail"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                goToLink("/du-an");
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="home-box-title">Tất cả dự án</div>
              </div>
              <img className="arrow-see-more" />
            </div>
          </div>
        </div>
      )}
      {!mobileView ? (
        <div>
          {listProjectHighlight.length > 0 && (
            <Swiper
              navigation={{
                // Both prevEl & nextEl are null at render so this does not work
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              // navigation={true}
              pagination={{
                clickable: true,
                type: null,
              }}
              freeMode={false}
              slidesPerView={1.2}
              // freeMode={true}
              spaceBetween={32}
              // autoplay={{
              //   delay: 5000,
              //   disableOnInteraction: true,
              // }}
              // loop={true}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.activeIndex);
                // console.log(swiper.activeIndex);
              }}
              activeIndex={0}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                // swiper.navigation.init();
                swiper.navigation.update();
              }}
            >
              {listProjectHighlight !== [] &&
                listProjectHighlight.slice(0, 10).map((item, index) => {
                  return (
                    <SwiperSlide>
                      <div
                        onClick={() => {
                          goToLink("/du-an/" + item.pathSEO, item);
                        }}
                        className="slider-view"
                        style={{
                          backgroundSize: "cover",
                          height: 360,
                          backgroundImage: `url(${item.thumbnailHome})`,
                        }}
                      >
                        <div className="bg-slider">
                          <div className="slider-content">
                            <div className="slider-header ">DỰ ÁN NỔI BẬT</div>
                            <div className="slider-title">{item.name}</div>
                            {/* <div className="slider-description">
                              {item.shortDescription}
                            </div> */}
                            <div className="slider-description">
                              {item.scaleWorks}
                            </div>
                            {/* <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 16,
                          }}
                        >
                          <div className="slider-box">Quản Lý Dự Án</div>
                          <div className="slider-box">Vệ Sinh Công Nghiệp</div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              <div
                ref={nextRef}
                className="view-opacity-right"
                style={{
                  width: window.innerWidth / 6 - 20,
                  opacity:
                    activeIndex < listProjectHighlight.length - 1 ? 1 : 0,
                }}
              >
                <img
                  style={{ borderRadius: 50, width: 56, height: 56 }}
                  src={button_next}
                  className="hover-ct"
                />
              </div>

              <div
                ref={prevRef}
                className="view-opacity-left"
                style={{
                  width: window.innerWidth / 8 - 20,
                  opacity: activeIndex > 0 ? 1 : 0,
                }}
              >
                <img
                  src={button_next}
                  className="view-opacity-left-img view-opacity-left-hover-ct"
                />
              </div>
            </Swiper>
          )}
        </div>
      ) : (
        <div>
          <section id="section-1">
            {listProjectHighlight.length > 0 &&
              listProjectHighlight.map((item) => {
                return (
                  <div
                    style={{
                      backgroundSize: "cover",
                      height: 500,
                      backgroundImage: `url(${item.thumbnailHomeMb})`,
                      marginBottom: 16,
                      display: "flex",
                      // marginTop: 100,
                    }}
                  >
                    <div
                      className="bg-slider-mb"
                      onClick={() => {
                        goToLink("/du-an/" + item.pathSEO, item);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          // alignItems: "flex-end",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          marginBottom: 56,
                          marginLeft: 16,
                        }}
                      >
                        <div className="slider-header ">DỰ ÁN NỔI BẬT</div>
                        <div className="slider-title">{item.name}</div>
                        {/* <div className="slider-description">
                        {item.shortDescription}
                      </div> */}
                        <div className="slider-description">
                          {item.scaleWorks}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </section>
        </div>
      )}
    </div>
  );
}

Highlights.propTypes = {
  className: PropTypes.string,
};

export default Highlights;
