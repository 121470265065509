/* eslint-disable react/react-in-jsx-scope */
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import React, { Component } from "react";
import { useEffect, useLayoutEffect, useState } from "react";
import logo from "../../../assets/images/marker.png";
import "./index.css";

const { innerWidth: width, innerHeight: height } = window;
var iconPin = {
  path: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z",
  fillColor: "#64be67",
  fillOpacity: 1,
  scale: 0.05, //to reduce the size of icons
};
const openInNewTab = (input) => {
  if (input !== undefined) {
    const newWindow = window.open(input, "_blank");
    if (newWindow) newWindow.opener = null;
  }
};
const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultOptions={{
        styles: exampleMapStyles,
      }}
      defaultZoom={props.defaultZoom}
      center={props.defaultCenter}
    >
      {props.isMarkerShown && (
        <div>
          {/* <div className="title-map ">Nam Sài Gòn</div> */}

          <Marker
            onClick={() => {
              openInNewTab(props.mapUrl);
            }}
            icon={{
              url: logo,
              scaledSize: new window.google.maps.Size(33, 42),
            }}
            position={props.defaultCenter}
          >
            <InfoWindow
              pixelOffset={"0"}
              // marker={this.state.activeMarker}
              // visible={this.state.showingInfoWindow}
            >
              <div className="title-map">Nam Sài Gòn</div>
            </InfoWindow>
          </Marker>
        </div>
      )}
    </GoogleMap>
  ))
);
const exampleMapStyles = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#dedede",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#333333",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];

const MapContainer = (props) => {
  const [size, setSize] = useState([0, 0]);
  const [widthMap, setWidthMap] = useState("680px");
  const [containerStyle, setContainerStyle] = useState({
    position: "relative",
    width: "680px",
    height: "680px",
  });
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useEffect(() => {
    if (size[0] > 1660) {
      // setContainerStyle({
      //   position: "relative",
      //   width: "680px",
      //   height: "680px",
      // });
      setWidthMap("680px");
    }
    if (size[0] <= 1660 && size[0] > 1024) {
      // setContainerStyle({
      //   position: "relative",
      //   width: size[0] / 3,
      //   height: size[0] / 3,
      // });
      // alert(size);
      setWidthMap("550px");
    }
    if (size[0] <= 1024 && size[0] > 768) {
      // setContainerStyle({
      //   position: "relative",
      //   width: "310px",
      //   height: "310px",
      // });
      setWidthMap("310px");
    }
    if (size[0] <= 768) {
      // setContainerStyle({
      //   position: "relative",
      //   width: "310px",
      //   height: "310px",
      // });
      setWidthMap(size[0]);
    }
  }, [size]);
  return (
    <div>
      {/* {props.data.Lat !== "" && ( */}
      <MyMapComponent
        // mapUrl={props.data.LinkMap}
        isMarkerShown
        defaultZoom={14}
        defaultCenter={{
          lat: parseFloat(10.86914639826975),
          lng: parseFloat(106.62670681317468),
        }}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCrT0Ch7IL77c0-E2LoRZP8Hg5yY4BQ9Gw&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `1000px` }} />}
        containerElement={<div className="map-contemt" />}
        mapElement={<div style={{ height: `100%`, borderRadius: 10 }} />}
      />
      {/* )} */}
    </div>
  );
};

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyDFpb67jhOmWbT588UmHKGLs0usVMHBjt4",
// })(MapContainer);
export default MapContainer;
