import jwtDecode from "jwt-decode";
import axios from "src/utils/axios";

class UserService {
  create = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post("/user/create", payload)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  createadmin = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post("/user/createadmin", payload)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getList = () =>
    new Promise((resolve, reject) => {
      axios
        .get("/user/users")
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getDetail = (path) =>
    new Promise((resolve, reject) => {
      axios
        .get("/area/detail/" + path)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  editItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .put("/area/edit?itemID=" + payload.id, payload)
        .then((response) => {
          console.log("response", response);
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  deleteItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .delete("/area/delete?itemID=" + payload.id)
        .then((response) => {
          console.log("response", response);
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
}

const userService = new UserService();

export default userService;
