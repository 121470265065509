/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
} from "react-feather";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";

const navConfig = [
  {
    subheader: "Quản lý",
    items: [
      {
        title: "Tổng quan",
        icon: EditIcon,
        href: "/app/management/general",
      },
      // {
      //   title: "Customers",
      //   icon: UsersIcon,
      //   href: "/app/management/customers",
      //   items: [
      //     {
      //       title: "List Customers",
      //       href: "/app/management/customers",
      //     },
      //     {
      //       title: "View Customer",
      //       href: "/app/management/customers/1",
      //     },
      //     {
      //       title: "Edit Customer",
      //       href: "/app/management/customers/1/edit",
      //     },
      //   ],
      // },
      {
        title: "Tin tức",
        icon: EditIcon,
        href: "/app/management/products",
        items: [
          {
            title: "Danh sách",
            href: "/app/management/products",
          },
          {
            title: "Thêm mới ",
            href: "/app/management/products/create",
          },
        ],
      },
      {
        title: "Dự án",
        icon: EditIcon,
        href: "/app/management/products",
        items: [
          {
            title: "Danh sách",
            href: "/app/management/projects",
          },
          {
            title: "Thêm mới ",
            href: "/app/management/project/create",
          },
          {
            title: "Sắp xếp nổi bật",
            href: "/app/management/project/sort",
          },
        ],
      },
      {
        title: "Tuyển dụng",
        icon: UsersIcon,
        href: "/app/management/editor",
        items: [
          {
            title: "Danh sách",
            href: "/app/management/jobs",
          },
          {
            title: "Thêm mới",
            href: "/app/management/job/create",
          },
        ],
      },
      {
        title: "Feedback khách hàng",
        icon: UsersIcon,
        href: "/app/management/feedback",
        items: [
          {
            title: "Danh sách",
            href: "/app/management/feedbacks",
          },
          {
            title: "Thêm mới",
            href: "/app/management/feedback/create",
          },
        ],
      },
      {
        title: "Khu vực dự án",
        icon: EditIcon,
        href: "/app/management/areas",
        items: [
          {
            title: "Danh sách",
            href: "/app/management/areas",
          },
          {
            title: "Thêm mới",
            href: "/app/management/area/create",
          },
        ],
      },
      {
        title: "Quản lý thông tin",
        icon: EditIcon,
        href: "/app/management/infomation",
      },
      {
        title: "Quản lý liên hệ",
        icon: EditIcon,
        href: "/app/management/contacts",
      },
      {
        title: "Hinh ảnh",
        icon: EditIcon,
        href: "/app/management/images",
      },
      {
        title: "Biên tập viên",
        icon: UsersIcon,
        href: "/app/management/editor",
        items: [
          {
            title: "Danh sách",
            href: "/app/management/editors",
          },
          {
            title: "Thêm mới",
            href: "/app/management/editor/create",
          },
        ],
      },
      // {
      //   title: "Orders",
      //   icon: FolderIcon,
      //   href: "/app/management/orders",
      //   items: [
      //     {
      //       title: "List Orders",
      //       href: "/app/management/orders",
      //     },
      //     {
      //       title: "View Order",
      //       href: "/app/management/orders/1",
      //     },
      //   ],
      // },
      // {
      //   title: "Invoices",
      //   icon: ReceiptIcon,
      //   href: "/app/management/invoices",
      //   items: [
      //     {
      //       title: "List Invoices",
      //       href: "/app/management/invoices",
      //     },
      //     {
      //       title: "View Invoice",
      //       href: "/app/management/invoices/1",
      //     },
      //   ],
      // },
    ],
  },
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    console.log("user", user);
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstName} ${user.lastName}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
