import React, { useEffect, useCallback, useState } from "react";
import Page from "src/components/Page";

import "./index.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import bgaboutus from "src/assets/images/bgaboutus.png";
import imgNew from "src/assets/images/new4.png";
import icon_back from "src/assets/images/icon_back.png";
import { useHistory, useParams } from "react-router-dom";
import { getJobDetailRequest, getJobOpenRelate } from "src/actions/jobActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 133,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    // background: url("../img/intro-bg.jpg"),
    height: 560,
    // backgroundImage: `url(images/bgjob.png)`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  introMb: {
    // background: url("../img/intro-bg.jpg"),
    height: 375,
    // backgroundImage: `url(images/bgjob.png)`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
}));
const paymentStatusColors = {
  canceled: "error",
  pending: "warning",
  completed: "success",
  rejected: "error",
};
const paginatedOrders = [
  { status: 1, position: "Trưởng phòng" },
  { status: 1, position: "Trưởng phòng" },
  { status: 1, position: "Nhân viên" },
];
function JobDetailView(props) {
  const classes = useStyles();
  useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [jobDetail, setJobDetail] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [listJobOpen, setListJobOpen] = useState([]);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const fetchData = useCallback((pathSEO) => {
    dispatch(
      getJobDetailRequest(
        pathSEO,
        (res) => {
          setJobDetail(res);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  }, []);
  const getJobOpenHandle = useCallback((pathSEO) => {
    dispatch(
      getJobOpenRelate(
        pathSEO,
        (res) => {
          setListJobOpen(res);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  }, []);
  useEffect(() => {
    if (
      props.location.state !== undefined &&
      props.location.state.pathSEO !== ""
    ) {
      setJobDetail(props.location.state);
    } else {
      fetchData(window.location.pathname.split("/")[2]);
    }
    getJobOpenHandle(window.location.pathname.split("/")[2]);
  }, [window.location.pathname]);
  const handleClick = () => {
    history.push("/nghe-nghiep");
  };
  const handleClickItem = (item) => {
    history.push({
      pathname: "/nghe-nghiep/" + item.pathSEO,
      state: item,
    });
  };
  return (
    <Page title="Nghề nghiệp">
      {/* <Hero /> */}
      <header style={{ background: "#fff" }} id="header">
        <div
          className={`${
            mobileView ? classes.introMb : classes.intro
          } border-image`}
          style={{ backgroundImage: `url(${bgaboutus})` }}
        ></div>
        <div className="container view-container-job-detail">
          {!mobileView ? (
            <div style={{ paddingRight: "17.5%", paddingLeft: "17.5%" }}>
              <div className="box-shadow">
                <div className="job-view-detail-top">
                  <div className="row-ct">
                    <div className="title-job">{jobDetail.position}</div>
                    {jobDetail.status === 0 ? (
                      <div className="job-status-close">Đã đóng</div>
                    ) : (
                      <div className="job-status">Còn mở</div>
                    )}
                  </div>
                  <div className="row-ct" style={{ marginTop: 24 }}>
                    <div className="tite-level">Trình độ</div>
                    <div className="title-detail">{jobDetail.level}</div>
                    <div className="tite-level">Số lượng</div>
                    <div className="title-detail">{jobDetail.count}</div>
                    <div className="tite-level">Ngày đăng</div>
                    <div className="title-detail">
                      {moment(Number.parseFloat(jobDetail.datePosted)).format(
                        "DD/MM/YYYY"
                      )}
                    </div>
                  </div>
                </div>
                <div className="job-view-detail">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jobDetail.body?.replaceAll("<br>", ""),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{}}>
              <div className="box-shadow">
                <div className="job-view-detail-top">
                  <div className="row-ct">
                    <div className="title-job">{jobDetail.position}</div>
                    {jobDetail.status === 0 ? (
                      <div className="job-status-close">Đã đóng</div>
                    ) : (
                      <div className="job-status">Còn mở</div>
                    )}
                  </div>
                  <div className="row-ct" style={{ marginTop: 24 }}>
                    <div className="tite-level">Trình độ</div>
                    <div className="title-detail">{jobDetail.level}</div>
                    <div className="tite-level">Số lượng</div>
                    <div className="title-detail">{jobDetail.count}</div>
                  </div>
                  <div className="row-ct">
                    <div className="tite-level">Ngày đăng</div>
                    <div className="title-detail">
                      {moment(Number.parseFloat(jobDetail.datePosted)).format(
                        "L"
                      )}
                    </div>
                  </div>
                </div>
                <div className="job-view-detail">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jobDetail.body?.replaceAll("<br>", ""),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="container"
          style={{
            paddingRight: "8.5%",
            paddingLeft: "8.5%",
            marginBottom: 98,
          }}
        >
          <div className="title-suggest">Công việc tương tự</div>
          {!mobileView ? (
            <Box>
              <Table style={{ marginTop: 56 }}>
                <TableHead>
                  <TableRow>
                    <td
                      className="col-md-1 title-header-table "
                      style={{ paddingLeft: 0, textAlign: "left" }}
                    >
                      STT
                    </td>
                    <td
                      className="col-md-3 title-header-table "
                      style={{ paddingLeft: 0, textAlign: "left" }}
                    >
                      Chức vụ
                    </td>
                    <td className="col-md-2 title-header-table ">Trình độ</td>
                    <td className="col-md-2 title-header-table ">Số lượng</td>
                    <td className="col-md-2 title-header-table ">Ngày đăng</td>
                    <td className="col-md-2 title-header-table " align="right">
                      Tình trạng
                    </td>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listJobOpen.length > 0 &&
                    listJobOpen.map((item, index) => {
                      return (
                        <TableRow
                          className="table-row hover-ct-row"
                          key={item.id}
                          onClick={() => {
                            handleClickItem(item);
                          }}
                          // selected={selectedOrders.indexOf(order.id) !== -1}
                        >
                          <td style={{ textAlign: "left" }}>{index + 1}</td>
                          <td>
                            <div
                              style={{ textAlign: "left" }}
                              className="title-position"
                            >
                              {item.position}
                            </div>
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <div className="title-level">{item.level}</div>
                            </div>
                          </td>
                          <td>1</td>
                          <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>

                          <td>
                            <div style={{ display: "flex" }}>
                              {item.status === 0 ? (
                                <div className="job-close">Đã đóng</div>
                              ) : (
                                <div className="job-open">Còn mở</div>
                              )}
                            </div>
                          </td>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <div style={{ marginTop: 24 }}>
              {listJobOpen.map((item) => {
                return (
                  <div
                    className="item-mb"
                    onClick={() => {
                      handleClickItem(item);
                    }}
                  >
                    <div className="title-position-mb">{item.position}</div>
                    <div className="row-ct">
                      <div className="title-time-mb">SL : {item.count}</div>
                      <div className="title-time-mb" style={{ marginLeft: 24 }}>
                        Ngày đăng :{" "}
                        {moment(Number.parseFloat(item.datePosted)).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                      {item.status === 0 ? (
                        <div className="job-close">Đã đóng</div>
                      ) : (
                        <div className="job-open">Còn mở</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {!mobileView ? (
          <div
            style={{
              position: "absolute",
              top: 250,
              width: "100%",
            }}
          >
            <div className="container">
              <img
                onClick={handleClick}
                style={{ borderRadius: 50 }}
                src={icon_back}
                className="hover-ct"
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "fixed",
              top: 110,
              width: "100%",
            }}
          >
            <div className="container">
              <img
                onClick={handleClick}
                src={icon_back}
                className="icon-back-mb"
              />
            </div>
          </div>
        )}
      </header>
    </Page>
  );
}

export default JobDetailView;
