import React, { useEffect, useState, useCallback, useRef } from "react";
import Page from "src/components/Page";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  MenuItem,
  ListItemText,
  Menu,
} from "@material-ui/core";
import "./index.css";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  getListProjectRequest,
  getListProjectAreaRequest,
  getListProjectAnotherRequest,
  getListProjectHighLightRequest,
} from "src/actions/projectActions";
import { getAreaRequest } from "src/actions/areaActions";
import { useDispatch } from "react-redux";
import arrowLeft from "src/assets/images/arrowLeft.png";
import arrowRight from "src/assets/images/arrowRight.png";
import arrowBottom from "src/assets/images/arrowBottom.png";
import { ToggleButtonGroup, ToggleButton, Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 133,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    height: 902,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  introMb: {
    height: 404,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#DA9E26",
    },
  },
}));
function ProjectsView() {
  const sortRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState({ title: "Tất cả", id: -1 });
  const [currentArea, setCurrentArea] = useState("");
  const [listProjectTop, setListProjectTop] = useState([]);
  const [listProjectBottom, setListProjectBottom] = useState([]);
  const [listProjectBottomFilter, setListProjectBottomFilter] = useState([]);
  const [listProjectHighlight, setListProjectHighlight] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const getListProject = useCallback(() => {
    dispatch(
      getListProjectAnotherRequest(
        (res) => {
          console.log("getListProjectAnotherRequest", res);
          setListProjectBottom(res);
          setListProjectBottomFilter(res);
        },
        (error) => {}
      )
    );
  }, []);
  const getListProjectHighLight = useCallback(() => {
    dispatch(
      getListProjectHighLightRequest(
        (res) => {
          setListProjectHighlight(res);
        },
        (error) => {}
      )
    );
  }, []);
  const getListArea = useCallback(() => {
    dispatch(
      getAreaRequest(
        (res) => {
          let currentArea = res.filter((item) => item.active === 1)[0];
          setCurrentArea(currentArea);
        },
        (error) => {}
      )
    );
  }, []);
  const getListProjectArea = useCallback(() => {
    dispatch(
      getListProjectAreaRequest(
        (res) => {
          setListProjectTop(res);
        },
        (error) => {}
      )
    );
  }, []);
  useEffect(() => {
    getListProject();
    getListProjectArea();
    getListArea();
    getListProjectHighLight();
  }, []);
  const handleSortSelect = (value) => {
    setSelectedSort(value);
    if (value.id === "-1") {
      setListProjectBottomFilter(listProjectBottom);
    } else {
      let newList = listProjectBottom.filter(
        (item) => item.status === value.id
      );

      setListProjectBottomFilter(newList);
    }
    setOpenSort(false);
  };

  const handleSortOpen = () => {
    setOpenSort(true);
  };

  const handleSortClose = () => {
    setOpenSort(false);
  };
  useStyles();
  const history = useHistory();
  const handleClick = (item) => {
    history.push({
      pathname: "/du-an/" + item.pathSEO,
      state: item,
    });
  };
  const onClick = () => {
    return <Redirect to="/posts/" />;
  };

  return (
    <Page title="Dự án">
      {/* <Hero /> */}
      <header id="header">
        <div style={{ background: "#F5F5F5", minHeight: 900 }}>
          <div
            className={`${
              mobileView ? classes.introMb : classes.intro
            } border-image`}
            style={{ backgroundImage: `url(${currentArea.thumbnail})` }}
          >
            <div className="container job-header"></div>
          </div>
          <div className="container view-container">
            <div className="col-md-6">
              <div
                className="header-title"
                data-aos="zoom-in-up"
                style={{ textAlign: "left" }}
              >
                {currentArea.name}
              </div>
              <div data-aos="zoom-in-up" className="txt-header">
                {currentArea.description}
              </div>
            </div>
          </div>

          <div className="container" style={{ marginTop: 10 }}>
            {mobileView ? (
              <div className="row">
                {listProjectTop.map((item) => {
                  return (
                    <div className="col-md-6">
                      <div
                        className="view-project-top"
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        <img className="img-project-top" src={item.thumbnail} />
                        <div className="title-project">{item.name}</div>
                        {item.status === "1" ? (
                          <div className="view-status-done">Đã thực hiện</div>
                        ) : (
                          <div className="view-status">Đang thực hiện</div>
                        )}
                        <div className="txt-address">{item.address}</div>
                        <div className="txt-scale ">{item.scaleWorks}</div>

                        {/* <div style={{ display: "flex" }}>
                      <div className="boder-tag">Vệ Sinh Công Nghiệp</div>
                      <div className="boder-tag">Chăm Sóc Cảnh Quang</div>
                    </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {listProjectTop.map((item) => {
                  return (
                    <div className="col-md-6">
                      <div
                        className="view-project-top"
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        <img className="img-project-top" src={item.thumbnail} />
                        <div className="view-title-project-top">
                          <div className="title-project">{item.name}</div>
                          {item.status === "1" ? (
                            <div className="view-status-done">Đã thực hiện</div>
                          ) : (
                            <div className="view-status">Đang thực hiện</div>
                          )}
                        </div>
                        <div className="txt-address">{item.address}</div>
                        <div className="txt-scale ">{item.scaleWorks}</div>

                        {/* <div style={{ display: "flex" }}>
                      <div className="boder-tag">Vệ Sinh Công Nghiệp</div>
                      <div className="boder-tag">Chăm Sóc Cảnh Quang</div>
                    </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {mobileView ? (
            <div className="container row-ct" style={{ marginTop: 40 }}>
              <div className="title-another-project" style={{ flex: 1 }}>
                Dự án khác
              </div>
              <div
                className="view-filter"
                onClick={handleSortOpen}
                ref={sortRef}
              >
                {selectedSort.title}
                <img src={arrowBottom} />
              </div>
            </div>
          ) : (
            <div className="container row-ct" style={{ marginTop: 96 }}>
              <div
                className="title-another-project"
                style={{ paddingLeft: 15, flex: 1 }}
              >
                Dự án khác
              </div>
              <div className="text-filter" style={{ marginRight: 16 }}>
                Hoàn thành
              </div>
              <div
                className="view-filter"
                style={{ marginRight: 15 }}
                onClick={handleSortOpen}
                ref={sortRef}
              >
                {selectedSort.title}
                <img src={arrowBottom} />
              </div>
            </div>
          )}

          <div className="container">
            {mobileView ? (
              <div className="row">
                {listProjectBottomFilter.slice(0, 3).map((item) => {
                  return (
                    <div className="col-md-4">
                      <div
                        className="view-project-bottom "
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        <img
                          className="img-project-bottom"
                          src={item.thumbnail}
                        />

                        <div className="title-project-bottom">{item.name}</div>
                        {item.status === "1" ? (
                          <div className="view-status-done">Đã thực hiện</div>
                        ) : (
                          <div className="view-status">Đang thực hiện</div>
                        )}

                        <div className="txt-address-bottom">{item.address}</div>
                        <div className=" txt-scale-bottom ">
                          {item.scaleWorks}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {listProjectBottomFilter
                  .slice((currentPage - 1) * 12, (currentPage - 1) * 12 + 3)
                  .map((item) => {
                    return (
                      <div className="col-md-4">
                        <div
                          className="view-project-bottom "
                          onClick={() => {
                            handleClick(item);
                          }}
                        >
                          <img
                            className="img-project-bottom"
                            src={item.thumbnail}
                          />
                          <div className="view-title-project-top">
                            <div className="title-project-bottom">
                              {item.name}
                            </div>
                            {item.status === "1" ? (
                              <div className="view-status-done">
                                Đã thực hiện
                              </div>
                            ) : (
                              <div className="view-status-bottom">
                                Đang thực hiện
                              </div>
                            )}
                          </div>
                          <div className="txt-address-bottom">
                            {item.address}
                          </div>
                          <div className=" txt-scale-bottom ">
                            {item.scaleWorks}
                          </div>
                          {/* <div style={{ display: "flex" }}>
                      <div className="boder-tag-bottom">
                        Vệ Sinh Công Nghiệp
                      </div>
                      <div className="boder-tag-bottom">
                        Chăm Sóc Cảnh Quang
                      </div>
                    </div> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className={mobileView ? "" : "container"}>
            {!!listProjectHighlight[(currentPage - 1) * 2] && (
              <div
                onClick={() => {
                  handleClick(listProjectHighlight[(currentPage - 1) * 2]);
                }}
                className="slider-view"
                style={{
                  marginRight: mobileView ? 0 : 15,
                  marginLeft: mobileView ? 0 : 15,
                  backgroundSize: "cover",
                  height: 360,
                  backgroundImage: `url(${
                    listProjectHighlight[(currentPage - 1) * 2].thumbnailHome
                  })`,
                }}
              >
                <div className="bg-slider" style={{ marginTop: 40 }}>
                  <div className="slider-content">
                    <div className="slider-header ">DỰ ÁN NỔI BẬT</div>
                    <div className="slider-title">
                      {listProjectHighlight[(currentPage - 1) * 2].name}
                    </div>
                    <div className="slider-description">
                      {listProjectHighlight[(currentPage - 1) * 2].scaleWorks}
                    </div>
                    {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 16,
                    }}
                  >
                    <div className="slider-box">Quản Lý Dự Án</div>
                    <div className="slider-box">Vệ Sinh Công Nghiệp</div>
                  </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="container">
            {mobileView ? (
              <div className="row">
                {listProjectBottomFilter
                  .slice(3, listProjectBottomFilter.length)
                  .map((item) => {
                    return (
                      <div className="col-md-4">
                        <div
                          className="view-project-bottom "
                          onClick={() => {
                            handleClick(item);
                          }}
                        >
                          <img
                            className="img-project-bottom"
                            src={item.thumbnail}
                          />

                          <div className="title-project-bottom">
                            {item.name}
                          </div>
                          {item.status === "1" ? (
                            <div className="view-status-done">Đã thực hiện</div>
                          ) : (
                            <div className="view-status">Đang thực hiện</div>
                          )}

                          <div className="txt-address-bottom">
                            {item.address}
                          </div>
                          <div className=" txt-scale-bottom ">
                            {item.scaleWorks}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div>
                {listProjectBottomFilter
                  .slice((currentPage - 1) * 12 + 3, (currentPage - 1) * 12 + 6)
                  .map((item) => {
                    return (
                      <div className="col-md-4">
                        <div
                          className="view-project-bottom "
                          onClick={() => {
                            handleClick(item);
                          }}
                        >
                          <img
                            className="img-project-bottom"
                            src={item.thumbnail}
                          />
                          <div className="view-title-project-top">
                            <div className="title-project-bottom">
                              {item.name}
                            </div>
                            {item.status === "1" ? (
                              <div className="view-status-done">
                                Đã thực hiện
                              </div>
                            ) : (
                              <div className="view-status-bottom">
                                Đang thực hiện
                              </div>
                            )}
                          </div>
                          <div className="txt-address-bottom">
                            {item.address}
                          </div>
                          <div className=" txt-scale-bottom ">
                            {item.scaleWorks}
                          </div>

                          {/* <div style={{ display: "flex" }}>
                      <div className="boder-tag-bottom">
                        Vệ Sinh Công Nghiệp
                      </div>
                      <div className="boder-tag-bottom">
                        Chăm Sóc Cảnh Quang
                      </div>
                    </div> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className={mobileView ? "" : "container"}>
            {!!listProjectHighlight[(currentPage - 1) * 2 + 1] && (
              <div
                onClick={() => {
                  handleClick(listProjectHighlight[(currentPage - 1) * 2 + 1]);
                }}
                className="slider-view"
                style={{
                  marginRight: mobileView ? 0 : 15,
                  marginLeft: mobileView ? 0 : 15,
                  backgroundSize: "cover",
                  height: 360,
                  backgroundImage: `url(${
                    listProjectHighlight[(currentPage - 1) * 2 + 1]
                      .thumbnailHome
                  })`,
                }}
              >
                <div className="bg-slider" style={{ marginTop: 40 }}>
                  <div className="slider-content">
                    <div className="slider-header ">DỰ ÁN NỔI BẬT</div>
                    <div className="slider-title">
                      {listProjectHighlight[(currentPage - 1) * 2 + 1].name}
                    </div>

                    <div className="slider-description">
                      {
                        listProjectHighlight[(currentPage - 1) * 2 + 1]
                          .scaleWorks
                      }
                    </div>
                    {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 16,
                    }}
                  >
                    <div className="slider-box">Quản Lý Dự Án</div>
                    <div className="slider-box">Vệ Sinh Công Nghiệp</div>
                  </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={"container"}>
            <div>
              {listProjectBottomFilter
                .slice((currentPage - 1) * 12 + 6, (currentPage - 1) * 12 + 11)
                .map((item) => {
                  return (
                    <div className="col-md-4">
                      <div
                        className="view-project-bottom "
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        <img
                          className="img-project-bottom"
                          src={item.thumbnail}
                        />
                        <div className="view-title-project-top">
                          <div className="title-project-bottom">
                            {item.name}
                          </div>
                          {item.status === "1" ? (
                            <div className="view-status-done">Đã thực hiện</div>
                          ) : (
                            <div className="view-status-bottom">
                              Đang thực hiện
                            </div>
                          )}
                        </div>
                        <div className="txt-address-bottom">{item.address}</div>
                        <div className=" txt-scale-bottom ">
                          {item.scaleWorks}
                        </div>

                        {/* <div style={{ display: "flex" }}>
                      <div className="boder-tag-bottom">
                        Vệ Sinh Công Nghiệp
                      </div>
                      <div className="boder-tag-bottom">
                        Chăm Sóc Cảnh Quang
                      </div>
                    </div> */}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="page-view">
            {listProjectBottomFilter.length > 12 && (
              <Pagination
                size="large"
                classes={{ ul: classes.ul }}
                count={
                  (listProjectBottomFilter.length -
                    (listProjectBottomFilter.length % 5)) /
                    5 +
                  1
                }
                onChange={handleChange}
                page={currentPage}
              />
            )}
          </div>
        </div>
        <Menu
          anchorEl={sortRef.current}
          className={classes.menu}
          onClose={handleSortClose}
          open={openSort}
          elevation={1}
        >
          {[
            { title: "Tất cả", id: "-1" },
            { title: "Đang thực hiện", id: "0" },
            { title: "Đã thực hiện", id: "1" },
          ].map((option) => (
            <MenuItem key={option} onClick={() => handleSortSelect(option)}>
              <ListItemText primary={option.title} />
            </MenuItem>
          ))}
        </Menu>
      </header>
    </Page>
  );
}

export default ProjectsView;
