import axios from "axios";
axios.defaults.baseURL = "";
axios.defaults.headers.post["Content-Type"] = "application/json";
const instance = axios.create({
  //   baseURL: "1201.1.1.1:8080/",
  // baseURL: "http://localhost:8080",
  baseURL: "https://namsaigonpm.vn:8080",
});

export default instance;
