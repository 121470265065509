import React, { useEffect, useCallback, useState } from "react";
import Page from "src/components/Page";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./index.css";
import imgNew from "src/assets/images/new4.png";
import icon_back from "src/assets/images/icon_back.png";
import { useHistory } from "react-router-dom";
import {
  getBlogDetailRequest,
  getBlogRelateRequest,
} from "../../../actions/blogActions";
import { useDispatch } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 128,
    paddingBottom: 128,
  },
  mbRoot: {
    paddingTop: 48,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    // background: url("../img/intro-bg.jpg"),
    height: 560,
    backgroundImage: `url(images/bgjob.png)`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
}));
function NewDetail(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [newDetail, setNewDetail] = useState([]);
  const [listNewsRelate, setListNewsRelate] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  useStyles();
  const getRelate = useCallback((pathSEO) => {
    dispatch(
      getBlogRelateRequest(
        pathSEO,
        (res) => {
          setListNewsRelate(res);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  }, []);
  const fetchData = useCallback((pathSEO) => {
    dispatch(
      getBlogDetailRequest(
        pathSEO,
        (res) => {
          setNewDetail(res.item);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  }, []);
  useEffect(() => {
    if (
      props.location.state !== undefined &&
      props.location.state.pathSEO !== ""
    ) {
      setNewDetail(props.location.state);
    } else {
      fetchData(window.location.pathname.split("/")[2]);
    }
    getRelate(window.location.pathname.split("/")[2]);
  }, [window.location.pathname]);
  const handleClick = () => {
    history.goBack();
  };
  const handleClickItem = (item) => {
    history.push({
      pathname: "/tin-tuc/" + item.pathSEO,
      state: item,
    });
  };
  return (
    <Page
      className={mobileView ? classes.mbRoot : classes.root}
      title="Tin tức"
    >
      <div className={mobileView ? "" : "container"}>
        <div
          style={
            mobileView ? null : { paddingRight: "17.5%", paddingLeft: "17.5%" }
          }
        >
          <img className="img-header-detail" src={newDetail.thumbnail} />
          <div className="view-detail-top ">
            <div className="title-detai">{newDetail.title}</div>
            {mobileView ? (
              <div style={{ marginTop: 16 }}>
                <div className="row-ct">
                  <div className="txt-date-title">Ngày đăng</div>
                  <div className="txt-date-content">
                    {moment(Number.parseFloat(newDetail.datePosted)).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                </div>
                <div className="row-ct">
                  <div className="txt-date-title" style={{}}>
                    Nguồn
                  </div>
                  <div className="txt-date-content">{newDetail.source}</div>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", marginTop: 26 }}>
                <div className="txt-date-title">Ngày đăng</div>
                <div className="txt-date-content">
                  {moment(Number.parseFloat(newDetail.datePosted)).format(
                    "DD/MM/YYYY"
                  )}
                </div>
                <div className="txt-date-title" style={{ marginLeft: 40 }}>
                  Nguồn
                </div>
                <div className="txt-date-content">{newDetail.source}</div>
              </div>
            )}
          </div>
          <div className="view-detail">
            <div
              dangerouslySetInnerHTML={{
                __html: newDetail.body?.replaceAll("<br>", ""),
              }}
            ></div>
          </div>
        </div>
        <div className="title-suggest" style={{ marginLeft: 15 }}>
          Tin Tức khác
        </div>
        <div
          className="row"
          style={{ marginTop: 40, marginLeft: 0, marginRight: 0 }}
        >
          {listNewsRelate.length > 0 &&
            listNewsRelate.map((item) => {
              return (
                <div
                  data-aos="flip-left"
                  className={mobileView ? "col-md-6" : "col-md-4 "}
                >
                  <div
                    className="new-item-list hover-ct"
                    onClick={() => {
                      handleClickItem(item);
                    }}
                  >
                    <img className="new-item-img" src={item.thumbnail} />
                    <div className="new-item-title">{item.title}</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: 16,
                        marginRight: 16,
                        marginTop: 8,
                      }}
                    >
                      <div className="item-time">
                        {moment(Number.parseFloat(item.datePosted)).format(
                          "DD/MM/YYY"
                        )}
                      </div>
                      <div
                        className="item-see-now"
                        style={{ marginLeft: "auto" }}
                      >
                        Xem ngay
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {mobileView ? (
        <div
          style={{
            position: "fixed",
            top: 110,
            width: "100%",
          }}
        >
          <div className="container">
            <img
              onClick={handleClick}
              src={icon_back}
              className="icon-back-mb"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            top: 250,
            width: "100%",
          }}
        >
          <div className="container">
            <img
              onClick={handleClick}
              style={{ borderRadius: 50 }}
              src={icon_back}
              className="hover-ct"
            />
          </div>
        </div>
      )}
    </Page>
  );
}

export default NewDetail;
