import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./About.css";
import arrow2 from "src/assets/images/arrow2.png";
import bghomepng1 from "src/assets/images/bghomepng1.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
}));

function About({ className, mobileView, goToLink, ...rest }) {
  const classes = useStyles();

  return (
    <header id="header">
      <div className={""}>
        <div className="container">
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: !mobileView ? 160 : 20,
              marginBottom: !mobileView ? 120 : 24,
            }}
          >
            {!mobileView ? (
              <div class="col-md-10">
                <div class="col-md-6" data-aos="fade-right">
                  <div className={"about-title1"}>Giới thiệu công ty</div>
                  <div className={"title"}>Về Nam Saigon</div>
                  <div className={"about-content"}>
                    Công ty Nam Saigon cung cấp đầy đủ dịch vụ quản lý, vận hành
                    chất lượng cao với bất kỳ loại hình BĐS như khu căn hộ, cao
                    ốc văn phòng, khu thương mại – dịch vụ, khu phức hợp, tổ hợp
                    công nghiệp… nhằm hướng đến sự an toàn, văn minh và tối ưu
                    hoá chi phí cho khách hàng.
                  </div>
                  <div
                    className={"about-box"}
                    onClick={() => {
                      goToLink("/ve-namsaigon");
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <span className={"about-box-title"}>Chi tiết</span>
                    </div>
                    <img className="arrow-see-more" src={arrow2} />
                  </div>
                </div>
                <div class="col-md-6" data-aos="fade-left">
                  <div className="about-image-border">
                    <img className="about-image-view" src={bghomepng1} />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div class="col-md-12" data-aos="fade-right">
                  <div className={"about-title1"}>Giới thiệu công ty</div>
                  <div className={"title"}>Về Nam Saigon</div>
                  <div className={"about-content"}>
                    Công ty Nam Saigon cung cấp đầy đủ dịch vụ quản lý, vận hành
                    chất lượng cao với bất kỳ loại hình BĐS như khu căn hộ, cao
                    ốc văn phòng, khu thương mại – dịch vụ, khu phức hợp, tổ hợp
                    công nghiệp… nhằm hướng đến sự an toàn, văn minh và tối ưu
                    hoá chi phí cho khách hàng.
                  </div>
                  <a href="/ve-namsaigon">
                    <div className={"about-box"}>
                      <div style={{ flex: 1 }}>
                        <span className={"about-box-title"}>Chi tiết</span>
                      </div>
                      <img className="arrow-see-more" />
                    </div>
                  </a>
                </div>
                <div
                  class="col-md-12"
                  data-aos="fade-left"
                  style={{ marginLeft: 12, marginTop: 44 }}
                >
                  <div className="about-image-border">
                    <img className="about-image-view" src={bghomepng1} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

About.propTypes = {
  className: PropTypes.string,
};

export default About;
