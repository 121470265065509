import React, { useEffect, useCallback, useState } from "react";
import Page from "src/components/Page";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./index.css";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  getBlogRequest,
  getBlogHighLightRequest,
  getBlogAnotherRequest,
} from "../../../actions/blogActions";
import { useDispatch } from "react-redux";
import { ToggleButtonGroup, ToggleButton, Pagination } from "@material-ui/lab";
import moment from "moment";

// title: "Nam Sài Gòn đủ diều kiện vận hành nhà chưng cư",
// thumbnail: "images/new1.png",
// time: "16/6/2020",

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 128,
    paddingBottom: 128,
  },
  rootMb: {
    paddingTop: 48,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    // background: url("../img/intro-bg.jpg"),
    height: 560,
    backgroundImage: `url(images/bgjob.png)`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#DA9E26",
    },
  },
}));
function NewsView() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [listNews, setListNews] = useState([]);
  const [listNewsHighLight, setListNewsHighLight] = useState([]);
  const [listNewsAnother, setListNewsAnother] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    if (!isLoading && !isLoading2)
      setListNewsAnother([...listNewsHighLight.slice(2), listNewsAnother]);
  }, [isLoading, isLoading2]);
  useStyles();
  useEffect(() => {
    getProjects();
  }, []);
  const getProjects = useCallback(() => {
    dispatch(
      getBlogRequest(
        (res) => {
          setListNews(res);
        },
        (error) => {}
      )
    );
    dispatch(
      getBlogHighLightRequest(
        (res) => {
          setListNewsHighLight(res);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
    dispatch(
      getBlogAnotherRequest(
        (res) => {
          setListNewsAnother(res);
          setLoading2(false);
        },
        (error) => {
          setLoading2(false);
        }
      )
    );
  }, []);
  const handleClick = (item) => {
    if (item.pathSEO)
      history.push({
        pathname: "/tin-tuc/" + item.pathSEO,
        state: item,
      });
  };
  return (
    <Page
      className={mobileView ? classes.rootMb : classes.root}
      title="Tin tức"
    >
      <div className="container" style={{ minHeight: 900 }}>
        <div className={"row"}>
          {!mobileView ? (
            <div data-aos="flip-left" className="col-md-8">
              {listNewsHighLight.length > 0 && (
                <div
                  className="new-top-left hover-ct"
                  onClick={() => {
                    handleClick(listNewsHighLight[0]);
                  }}
                >
                  <img
                    className="new-img-top"
                    src={listNewsHighLight[0].thumbnail}
                  />
                  <div className="new-title-left">
                    {listNewsHighLight[0].title}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 24,
                      marginRight: 24,
                      marginTop: 8,
                    }}
                  >
                    <div className="item-time">
                      {moment(
                        Number.parseFloat(listNewsHighLight[0].datePosted)
                      ).format("DD/MM/YYYY")}
                    </div>
                    <div className="item-time" style={{ marginLeft: 16 }}>
                      {listNewsHighLight[0].source}
                    </div>
                    <div
                      className="item-see-now"
                      style={{ marginLeft: "auto" }}
                    >
                      Xem ngay
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              {listNewsHighLight.length > 0 && (
                <div
                  className="new-top-left hover-ct"
                  onClick={() => {
                    handleClick(listNewsHighLight[0]);
                  }}
                >
                  <img
                    className="new-img-top"
                    src={listNewsHighLight[0].thumbnail}
                  />
                  <div className="new-item-title">
                    {listNewsHighLight[0].title}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 15,
                      marginRight: 15,
                      marginTop: 8,
                    }}
                  >
                    <div className="item-time">
                      {moment(
                        Number.parseFloat(listNewsHighLight[0].datePosted)
                      ).format("DD/MM/YYY")}
                    </div>
                    <div className="item-time" style={{ marginLeft: 16 }}>
                      {listNewsHighLight[0].source}
                    </div>
                    <div
                      className="item-see-now"
                      style={{ marginLeft: "auto" }}
                    >
                      Xem ngay
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {!mobileView && listNewsHighLight.length > 1 && (
            <div data-aos="flip-left" className="col-md-4">
              <div
                className="new-top-left hover-ct"
                onClick={() => {
                  handleClick(listNewsHighLight[1]);
                }}
              >
                <img
                  className="new-img-top"
                  src={listNewsHighLight[1].thumbnail}
                />
                <div className="new-title-right">
                  {listNewsHighLight[1].title}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 24,
                    marginRight: 24,
                    marginTop: 8,
                  }}
                >
                  <div className="item-time">
                    {moment(
                      Number.parseFloat(listNewsHighLight[1].datePosted)
                    ).format("DD/MM/YYY")}
                  </div>
                  <div className="item-see-now" style={{ marginLeft: "auto" }}>
                    Xem ngay
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="title-news">Tin Tức khác</div>

        {!mobileView ? (
          <div className={"row"}>
            {listNewsAnother.map((item) => {
              if (!!item?.thumbnail)
                return (
                  <div data-aos="flip-left" className="col-md-4">
                    <div
                      className="new-item-list hover-ct"
                      onClick={() => {
                        handleClick(item);
                      }}
                    >
                      <img className="new-item-img" src={item.thumbnail} />
                      <div className="new-item-title">{item.title}</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 16,
                          marginRight: 16,
                          marginTop: 8,
                        }}
                      >
                        <div className="item-time">
                          {moment(Number.parseFloat(item.datePosted)).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                        <div
                          className="item-see-now"
                          style={{ marginLeft: "auto" }}
                        >
                          Xem ngay
                        </div>
                      </div>
                    </div>
                  </div>
                );
            })}
          </div>
        ) : (
          <div className={"row"}>
            {listNewsHighLight.length > 1 && (
              <div data-aos="flip-left" className="col-md-4">
                <div
                  className="new-item-list hover-ct"
                  onClick={() => {
                    handleClick(listNewsHighLight[1]);
                  }}
                >
                  <img
                    className="new-item-img"
                    src={listNewsHighLight[1].thumbnail}
                  />
                  <div className="new-item-title">
                    {listNewsHighLight[1].title}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 16,
                      marginRight: 16,
                      marginTop: 8,
                    }}
                  >
                    <div className="item-time">
                      {moment(
                        Number.parseFloat(listNewsHighLight[1].datePosted)
                      ).format("DD/MM/YYYY")}
                    </div>
                    <div
                      className="item-see-now"
                      style={{ marginLeft: "auto" }}
                    >
                      Xem ngay
                    </div>
                  </div>
                </div>
              </div>
            )}
            {listNewsAnother.map((item) => {
              console.log(`listNewsAnother`, item);
              if (!!item?.thumbnail)
                return (
                  <div className="col-md-4">
                    <div
                      className="new-item-list hover-ct"
                      onClick={() => {
                        handleClick(item);
                      }}
                    >
                      <img className="new-item-img" src={item.thumbnail} />
                      <div className="new-item-title">{item.title}</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 16,
                          marginRight: 16,
                          marginTop: 8,
                        }}
                      >
                        <div className="item-time">
                          {moment(Number.parseFloat(item.datePosted)).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                        <div
                          className="item-see-now"
                          style={{ marginLeft: "auto" }}
                        >
                          Xem ngay
                        </div>
                      </div>
                    </div>
                  </div>
                );
            })}
          </div>
        )}

        {/* {!mobileView && listNewsAnother.length > 10 && (
          <div className="page-view">
            <Pagination
              size="large"
              classes={{ ul: classes.ul }}
              count={
                (listNewsAnother.length - (listNewsAnother.length % 9)) / 9 + 1
              }
              onChange={handleChange}
              page={currentPage}
            />
          </div>
        )} */}
      </div>
    </Page>
  );
}

export default NewsView;
