import jwtDecode from "jwt-decode";
import axios from "src/utils/axios";

class BlogService {
  create = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post("/blog/create", payload)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data.post);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getList = () =>
    new Promise((resolve, reject) => {
      axios
        .get("/blog/blogs")
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  deleteItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .delete("/blog/delete?postID=" + payload.id)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getDetail = (path) =>
    new Promise((resolve, reject) => {
      axios
        .get("/blog/detail/" + path)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  editItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .put("/blog/edit?itemID=" + payload.id, payload)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getListHighLight = () =>
    new Promise((resolve, reject) => {
      axios
        .get("/blog/highlight")
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getListAnother = () =>
    new Promise((resolve, reject) => {
      axios
        .get("/blog/another")
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getRelate = (path) =>
    new Promise((resolve, reject) => {
      axios
        .get("/blog/relate/" + path)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
}

const blogService = new BlogService();

export default blogService;
