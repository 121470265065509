import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Hero from "./Hero";
import Features from "./Features";
import Testimonials from "./Testimonials";
import CTA from "./CTA";
import FAQS from "./FAQS";
import HeaderTop from "./HeaderTop";
import About from "./About";
import MyServices from "./MyServices";
import Highlights from "./Highlights";
import Partners from "./Partners";
import News from "./News";
import Contact from "./Contact";
import { useHistory } from "react-router-dom";

import AOS from "aos";
import "./index.css";
const useStyles = makeStyles(() => ({
  root: {},
}));

function HomeView() {
  const classes = useStyles();
  useStyles();

  const [mobileView, setMobileView] = useState(false);
  const history = useHistory();
  const goToLink = (link, params) => {
    history.push({
      pathname: link,
      state: params,
    });
  };
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  return (
    <Page className={classes.root} title="Trang chủ">
      {/* <Hero /> */}
      <HeaderTop mobileView={mobileView} goToLink={goToLink} />
      <About mobileView={mobileView} goToLink={goToLink} />
      <Highlights mobileView={mobileView} goToLink={goToLink} />
      <MyServices mobileView={mobileView} goToLink={goToLink} />
      <Partners mobileView={mobileView} />
      <News mobileView={mobileView} goToLink={goToLink} />
      <Contact mobileView={mobileView} />
    </Page>
  );
}

export default HomeView;
