import MomentUtils from "@date-io/moment";
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AOS from "aos";
import "aos/dist/aos.css";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import Auth from "src/components/Auth";
import GoogleAnalytics from "src/components/GoogleAnalytics";
import ScrollReset from "src/components/ScrollReset";
import useSettings from "src/hooks/useSettings";
import Routes from "src/Routes";
import { createTheme } from "src/theme";

AOS.init();
const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  })
);

function App() {
  useStyles();
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  const { settings } = useSettings();

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                {/* <CookiesNotification /> */}
                {/* <SettingsNotification /> */}
                <Routes />
                {/* <RoutesAlternative /> */}
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
