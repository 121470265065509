import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./Partners.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import partner1 from "src/assets/images/partner1.png";
import partner2 from "src/assets/images/partner2.png";
import partner3 from "src/assets/images/partner3.png";
import partner4 from "src/assets/images/partner4.png";
import partner5 from "src/assets/images/partner5.png";
import partner6 from "src/assets/images/partner6.png";
import boss from "../../../assets/images/boss.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { getFeedbackRequest } from "src/actions/feedbackActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
}));
const slides = Array.from({ length: 3 }).map(
  (_, index) => `Slide ${index + 1}`
);

function Partners({ className, mobileView, ...rest }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [listFeedback, setListFeedback] = useState([]);
  useEffect(() => {
    dispatch(
      getFeedbackRequest(
        (res) => {
          console.log("getProjects", res);
          setListFeedback(res);
        },
        (error) => {
          console.log("error", error);
        }
      )
    );
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#fff",
        paddingTop: mobileView ? 40 : 120,
      }}
    >
      {!mobileView ? (
        <div className="container">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className="service-view"
          >
            <div className={"service-title"}>Đối tác</div>
            <div className={"service-content col-md-6"}>
              Cùng nhau phát triển, hợp tác bền vững. Chia sẻ lợi ích và trách
              nhiệm.
            </div>
          </div>
          <div
            className="row-ct"
            style={{ marginTop: 32, justifyContent: "center" }}
          >
            <img className="img-partner" src={partner1} />
            <img className="img-partner" src={partner2} />
            <img className="img-partner" src={partner3} />
          </div>
          <div
            className="row-ct"
            style={{ marginTop: 40, justifyContent: "center" }}
          >
            <img className="img-partner" src={partner4} />
            <img className="img-partner" src={partner5} />
            <img className="img-partner" src={partner6} />
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="boder-ct">
            <div className="boder-ct2">
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                className="service-view"
              >
                <div className={"service-title"}>Đối tác</div>
                <div className={"service-content col-md-6"}>
                  Cùng nhau phát triển, hợp tác bền vững. Chia sẻ lợi ích và
                  trách nhiệm.
                </div>
              </div>
              <div
                className="row-ct"
                style={{ marginTop: 20, justifyContent: "center" }}
              >
                <img className="img-partner" src={partner1} />
                <img className="img-partner" src={partner2} />
              </div>
              <div
                className="row-ct"
                style={{ marginTop: 20, justifyContent: "center" }}
              >
                <img className="img-partner" src={partner3} />
                <img className="img-partner" src={partner4} />
              </div>
              <div
                className="row-ct"
                style={{ marginTop: 20, justifyContent: "center" }}
              >
                <img className="img-partner" src={partner5} />
                <img className="img-partner" src={partner6} />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="swiper-partner-view">
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          className={""}
        >
          <Swiper
            navigation={
              mobileView
                ? { prevEl: prevRef.current, nextEl: nextRef.current }
                : {
                    // Both prevEl & nextEl are null at render so this does not work
                  }
            }
            // freeMode={true}
            slidesPerView={1}
            // freeMode={true}
            spaceBetween={32}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
              type: null,
            }}
            // onInit={(swiper) => {
            //   swiper.params.navigation.prevEl = prevRef.current;
            //   swiper.params.navigation.nextEl = nextRef.current;
            //   swiper.navigation.init();
            //   swiper.navigation.update();
            // }}
            className="mySwiper"
            // loop={true}
            onSlideChange={(swiper) => {
              //   setActiveIndex(swiper.activeIndex);
            }}
            activeIndex={0}
            // navigation={true}
          >
            {listFeedback !== [] &&
              listFeedback.slice(0, 10).map((item, index) => {
                return (
                  <SwiperSlide>
                    <div
                      className="swiper-partner-content"
                      style={{
                        backgroundSize: "cover",
                      }}
                    >
                      <img className="partner-img" src={item.thumbnail} />
                      <div className="partner-title col-md-7">
                        “{item.description}”
                      </div>
                      <div className="partner-footer ">{item.name}</div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

Partners.propTypes = {
  className: PropTypes.string,
};

export default Partners;
