import axios from "src/utils/axios";
import blogService from "src/services/blogService";
export function createBlogRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await blogService.create(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getBlogRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await blogService.getList();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function deleteBlogRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await blogService.deleteItem(payload);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getBlogDetailRequest(path, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await blogService.getDetail(path);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function editBLogRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await blogService.editItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}

export function getBlogHighLightRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await blogService.getListHighLight();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getBlogAnotherRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await blogService.getListAnother();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getBlogRelateRequest(path, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await blogService.getRelate(path);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
