import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import "./Contact.css";
import arrow from "src/assets/images/arrow.png";
import iconmessenger from "src/assets/images/iconmessenger.png";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createContactRequest } from "src/actions/contactActions";
import { useDispatch, useSelector } from "react-redux";
import { getInfomationRequest } from "src/actions/infomationActions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
}));

function Contact({ className, mobileView, ...rest }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [dataContact, setDataContact] = useState("");
  const handleChange = (event) => {
    event.persist();
    setValue(event.target.value);
  };
  useEffect(() => {
    dispatch(
      getInfomationRequest(
        (res) => {
          setDataContact(res);
        },
        (error) => {
          console.log("error", error);
        }
      )
    );
  }, []);
  const submit = () => {
    if (value !== "") {
      setIsLoading(true);
      let object = {
        type: 0,
        body: "Khách hàng cần liên hệ : " + value,
      };
      dispatch(
        createContactRequest(
          object,
          (res) => {
            setOpen(true);
            setValue("");
            setMessage(
              "Quý khách đã gửi thông tin thành công, NSG sẽ liên hệ trong thời gian sớm nhất!"
            );
          },
          (error) => {
            setOpen(true);
            setMessage("Đã có lỗi xảy ra, vui lòng thử lại!");
          }
        )
      );
    } else {
      setMessage("Vui lòng nhập đầy đủ thông tin");
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
    setValue("");
  };
  const setting = useSelector((state) => state.setting?.home);

  return (
    <div className="container">
      <div
        style={{
          height: 540,
          borderRadius: 8,
          backgroundImage: `url(${setting?.homeBannerSupport})`,
          backgroundSize: "cover",
          marginBottom: 145,
          marginTop: !mobileView ? 80 : 0,
        }}
      >
        <div className="bg-contact">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className="view-contact"
          >
            <div className="contact-title">
              Quý khách có dự án đang cần tư vấn?
            </div>
            {!mobileView ? (
              <div className="contact-input-view">
                <input
                  style={{ marginBottom: 0 }}
                  className="input-contact"
                  placeholder={
                    "Để lại email hoặc SĐT, chúng tôi sẽ liên lạc lại ngay với quý khách!"
                  }
                  type="text"
                  value={value}
                  onChange={handleChange}
                />

                {isLoading ? (
                  <div
                    className="button-see-more"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "auto",
                      marginRight: 16,
                      width: 300,
                    }}
                  >
                    <CircularProgress value={10} size={20} />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      submit();
                    }}
                    className="button-see-more"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "auto",
                      marginRight: 16,
                      width: 300,
                    }}
                  >
                    <div className="view-button-seemore">
                      <span className={"title-box"}>
                        Liên Lạc với chúng tôi
                      </span>
                    </div>
                    <img className="arrow-see-more" src={arrow} />
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="contact-input-view">
                  <input
                    style={{ marginBottom: 0 }}
                    className="input-contact-md"
                    placeholder={"Để lại email hoặc SĐT"}
                    type="text"
                    value={value}
                    onChange={handleChange}
                  />
                </div>
                {isLoading ? (
                  <div className="button-see-more">
                    <CircularProgress value={10} size={20} />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      submit();
                    }}
                    className="button-see-more"
                    style={{
                      marginTop: 16,
                      marginBottom: "auto",
                      marginLeft: "auto",
                      width: "100%",
                    }}
                  >
                    <div className="view-button-seemore">
                      <span className={"title-box"}>
                        Liên Lạc với chúng tôi
                      </span>
                    </div>
                    <img className="arrow-see-more" src={arrow} />
                  </div>
                )}
              </div>
            )}
            {!mobileView ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                  justifyContent: "center",
                }}
              >
                <div className="contact-content">
                  Hoặc quý khách có thể liên lạc ngay với chúng tôi thông qua
                  hot line
                </div>
                <div className="box-phone">{dataContact.hotline}</div>
                <div className="contact-content">
                  Hoặc hỗ trợ trực tuyến{" "}
                  <img style={{ width: 32, height: 32 }} src={iconmessenger} />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                }}
              >
                <div className="contact-content" style={{ fontSize: 14 }}>
                  Hoặc quý khách có thể liên lạc ngay với chúng tôi thông qua
                  hot line
                  <span className="box-phone-mb" style={{ width: 100 }}>
                    {dataContact.hotline}
                  </span>
                  Hoặc hỗ trợ trực tuyến{" "}
                  <img style={{ width: 32, height: 32 }} src={iconmessenger} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Thông báo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}
          <Button onClick={handleClose} color="primary" autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Contact.propTypes = {
  className: PropTypes.string,
};

export default Contact;
