import axios from "src/utils/axios";
import areaService from "src/services/areaService";
export function createAreaRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await areaService.create(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getAreaRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await areaService.getList();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getAreaDetailRequest(path, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await areaService.getDetail(path);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function editAreaRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await areaService.editItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function deleteAreaRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await areaService.deleteItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
