import axios from "src/utils/axios";
import projectService from "src/services/projectService";
export function createProjectRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await projectService.create(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getListProjectRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await projectService.getList();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getListProjectAnotherRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await projectService.getListAnother();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getListProjectHighLightRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await projectService.getListHighlight();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getListProjectAreaRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await projectService.getListTop();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function editProjectRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await projectService.editItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function deleteProjectRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await projectService.deleteItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getProjectDetailRequest(path, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await projectService.getDetail(path);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getListProjectHighLightHomeRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await projectService.getListHighlightHome();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getProjectRelateRequest(path, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await projectService.getRelate(path);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
