import axios from "src/utils/axios";
import infomationService from "src/services/infomationService";
export function createInfomationRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await infomationService.create(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getInfomationRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await infomationService.getList();
      dispatch({
        type: "UPDATE_SETTING",
        payload: list.data,
      });
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getInfomationDetailRequest(path, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await infomationService.getDetail(path);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function editInfomationRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await infomationService.editItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function editInfomationGeneralRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await infomationService.editItemGeneral(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function deleteInfomationRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await infomationService.deleteItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
