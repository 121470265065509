import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./Highlights.css";
import "swiper/swiper.min.css";
// import "swiper/components/pagination/pagination.min.css";
// import "swiper/components/navigation/navigation.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "./News.css";
import arrow2 from "src/assets/images/arrow2.png";
import { getBlogRequest } from "src/actions/blogActions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import button_next from "src/assets/images/button_next.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
}));

function News({ className, mobileView, goToLink, ...rest }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [listNew, setListNew] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  useEffect(() => {}, []);
  const getBlogRequestHanled = useCallback(() => {
    dispatch(
      getBlogRequest(
        (res) => {
          setListNew(res);
        },
        (error) => {}
      )
    );
  }, []);
  useEffect(() => {
    getBlogRequestHanled();
  }, []);
  const classes = useStyles();
  const [withView, setWithView] = useState(window.innerWidth);
  const [swiperRef, setSwiperRef] = useState(null);
  let appendNumber = 600;
  let prependNumber = 1;

  const prepend = () => {
    swiperRef.virtual.prependSlide([
      "Slide " + --prependNumber,
      "Slide " + --prependNumber,
    ]);
  };
  const handleClick = (item) => {
    history.push({
      pathname: "/tin-tuc/" + item.pathSEO,
      state: item,
    });
  };
  const append = () => {
    swiperRef.virtual.appendSlide("Slide " + ++appendNumber);
  };

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0);
  };

  // Create array with 1000 slides
  const slides = Array.from({ length: 5 }).map(
    (_, index) => `Slide ${index + 1}`
  );

  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="center-bottom"
      style={{ marginTop: mobileView ? 20 : 120 }}
    >
      <div className="container">
        <div className="row-custom">
          <div className="title-home">Tin tức</div>

          {!mobileView ? (
            <div
              className="home-box-detail "
              style={{ marginLeft: "auto" }}
              onClick={() => {
                goToLink("/tin-tuc");
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="home-box-title">Trang Tin Tức</div>
              </div>
              <img className="arrow-see-more" src={arrow2} />
            </div>
          ) : (
            <div
              onClick={() => {
                goToLink("/tin-tuc");
              }}
            >
              <span className={"about-box-title"}>Tất cả tin tức</span>
              <img
                className="arrow-see-more"
                style={{ marginRight: 0 }}
                src={arrow2}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={"container-new"}
        style={{
          marginTop: 40,
          marginLeft:
            withView > 1200
              ? (withView - 1140) / 2
              : withView > 970
              ? (withView - 970) / 2
              : withView > 768
              ? (withView - 750) / 2
              : 0,
        }}
      >
        <Swiper
          navigation={{
            // Both prevEl & nextEl are null at render so this does not work
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          freeMode={true}
          slidesPerView={!mobileView ? 3.33333 : 1.2}
          // freeMode={true}
          spaceBetween={0}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
            type: null,
          }}
          // onInit={(swiper) => {
          //   swiper.params.navigation.prevEl = prevRef.current;
          //   swiper.params.navigation.nextEl = nextRef.current;
          //   swiper.navigation.init();
          //   swiper.navigation.update();
          // }}
          // className="mySwiper"
          // loop={true}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
          }}
          activeIndex={0}
        >
          {listNew !== [] &&
            listNew.slice(0, 10).map((item, index) => {
              return (
                <SwiperSlide>
                  <div
                    className="slider-view news-box hover-ct"
                    style={{
                      backgroundSize: "cover",
                      height: 306,
                      marginLeft: 16,
                      borderRadius: 8,
                      marginRight: 16,
                    }}
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    <div
                      style={{
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8,
                        height: 200,
                        backgroundColor: "#9797",
                        backgroundImage: `url(${item.thumbnail})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                    <div className="new-item">
                      <div className="title-new">{item.title}</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 8,
                        }}
                      >
                        <div className="item-time">
                          {moment(Number.parseFloat(item.datePosted)).format(
                            "DD/MM/YYY"
                          )}
                        </div>
                        <div
                          className="item-see-now"
                          style={{ marginLeft: "auto" }}
                        >
                          Xem ngay
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          {!mobileView && (
            <div
              ref={nextRef}
              className="view-opacity-right-new"
              style={{
                width: window.innerWidth / 6 - 20,
                opacity: activeIndex < listNew.length - 3 ? 1 : 0,
              }}
            >
              <img
                style={{ borderRadius: 50, width: 56, height: 56 }}
                src={button_next}
                className="hover-ct"
              />
            </div>
          )}
          {!mobileView && (
            <div
              ref={prevRef}
              className="view-opacity-left-new"
              style={{
                width: window.innerWidth / 8 - 20,
                opacity: activeIndex > 0 ? 1 : 0,
              }}
            >
              <img
                src={button_next}
                className="view-opacity-left-img view-opacity-left-hover-ct"
              />
            </div>
          )}
        </Swiper>
      </div>
    </div>
  );
}

News.propTypes = {
  className: PropTypes.string,
};

export default News;
