import axios from "src/utils/axios";
import jobService from "src/services/jobService";
export function createJobRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await jobService.create(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getJobRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await jobService.getList();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getJobDetailRequest(path, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await jobService.getDetail(path);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function editJobRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await jobService.editItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function deleteJobRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await jobService.deleteItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getJobOpenRelate(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await jobService.getListOpenRelate(payload);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
