import axios from "src/utils/axios";
import contactService from "src/services/contactService";
export function createContactRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await contactService.create(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getContactList(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await contactService.getList();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
