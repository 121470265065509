/* eslint-disable no-param-reassign */
import produce from "immer";

const initialState = null;

const settingReducer = (state = initialState, action) => {
  console.log(`action`, action);
  switch (action.type) {
    case "UPDATE_SETTING": {
      return action.payload?.general;
    }
    default: {
      return state;
    }
  }
};

export default settingReducer;
