import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./index.css";
import arrow from "src/assets/images/arrow.png";
import bghomepng_mb from "src/assets/images/bghomepng_mb.png";
import bghomepng from "src/assets/images/bghomepng.png";
import { useSelector } from "react-redux";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    height: 900,
    // backgroundImage: `url(${bghomepng})`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
}));

function HeaderTop({ className, mobileView, goToLink, ...rest }) {
  const classes = useStyles();
  const home = useSelector((state) => state.setting?.home);
  console.log(`home`, home);
  return (
    <header id="header">
      <Swiper
        navigation={{
          // Both prevEl & nextEl are null at render so this does not work
          prevEl: null,
          nextEl: null,
        }}
        // navigation={true}
        pagination={{
          clickable: true,
          type: null,
        }}
        freeMode={false}
        slidesPerView={1}
        // freeMode={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        // loop={true}
      >
        {home?.images?.length > 0 &&
          home?.images.map((item, index) => {
            if (!!item.imageUrl && !!item.imageUrl !== "")
              return (
                <SwiperSlide>
                  {!mobileView ? (
                    <div
                      onClick={() => {
                        if (!!item.linkUrl && item.linkUrl !== "")
                          window.open(
                            item.linkUrl,
                            "_blank" // <- This is what makes it open in a new window.
                          );
                      }}
                      className={`${classes.intro} border-image`}
                      style={{
                        backgroundImage: `url(${item.imageUrl || "bghomepng"})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <div
                        className="container"
                        style={{
                          alignItems: "center",
                        }}
                      >
                        <div className="row">
                          <div class="col-md-5" data-aos="zoom-in-up">
                            <div className={"title"}>
                              Nam Saigon - <br /> Chung Tay Kiến Tạo
                            </div>
                            <div className={"content"}>
                              Chúng tôi với tôn chỉ kiến tạo sự văn minh và an
                              toàn, mong muốn mang lại dịch vụ chất lượng tốt để
                              phục vụ cư dân, khách hàng cảm thấy an tâm và chi
                              phí tối ưu nhất.
                            </div>
                            <div className={"box"}>
                              <div
                                className="view-button-seemore"
                                onClick={() => goToLink("/ve-namsaigon")}
                              >
                                <span className={"title-box"}>
                                  Tìm hiểu thêm
                                </span>
                              </div>
                              <img className="arrow-see-more2" src={arrow} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ height: 620 }}>
                      <div
                        className="bg-top-mb"
                        style={{
                          backgroundImage: `url(${item.imageUrlMobile ||
                            bghomepng_mb})`,
                          backgroundSize: "cover",
                          position: "relative",
                        }}
                      ></div>
                      <div
                        className="container"
                        style={{
                          alignItems: "center",
                          // paddingTop: 362,
                          position: "absolute",
                          bottom: 0,
                        }}
                      >
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div class="col-md-12" data-aos="zoom-in-up">
                            <div
                              style={{
                                background: "#fff",
                                borderRadius: 8,
                                padding: 24,
                              }}
                            >
                              <div className={"title"}>
                                Nam Saigon - Chung Tay Kiến Tạo
                              </div>
                              <div className={"content"}>
                                Chúng tôi là công ty chuyên cung cấp dịch vụ
                                quản lý - tư vấn quản lý bất động sản toàn diện
                                với mục tiêu cụ thể là tối ưu hóa giá trị.
                              </div>
                              <div className={"box"}>
                                <div className="view-button-seemore">
                                  <span className={"title-box"}>
                                    Tìm hiểu thêm
                                  </span>
                                </div>
                                <img className="arrow-see-more" src={arrow} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
              );
          })}
      </Swiper>
    </header>
  );
}

HeaderTop.propTypes = {
  className: PropTypes.string,
};

export default HeaderTop;
