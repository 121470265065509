import axios from "src/utils/axios";
import feedbackService from "src/services/feedbackService";
export function createFeedbackRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await feedbackService.create(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getFeedbackRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await feedbackService.getList();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getFeedbackDetailRequest(path, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await feedbackService.getDetail(path);
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function editFeedbackRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await feedbackService.editItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function deleteFeedbackRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await feedbackService.deleteItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
