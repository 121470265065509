import jwtDecode from "jwt-decode";
import axios from "src/utils/axios";

class AreaService {
  create = (payload) =>
    new Promise((resolve, reject) => {
      console.log("payload", payload);
      axios
        .post("/area/create", payload)
        .then((response) => {
          console.log(response);
          if (response.data.message === 1) {
            resolve(response.data.post);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getList = () =>
    new Promise((resolve, reject) => {
      axios
        .get("/area/areas")
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getDetail = (path) =>
    new Promise((resolve, reject) => {
      axios
        .get("/area/detail/" + path)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  editItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .put("/area/edit?itemID=" + payload.id, payload)
        .then((response) => {
          console.log("response", response);
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  deleteItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .delete("/area/delete?itemID=" + payload.id)
        .then((response) => {
          console.log("response", response);
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
}

const areaService = new AreaService();

export default areaService;
