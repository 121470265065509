import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./MyServices.css";
import arrow from "src/assets/images/arrow.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
}));

function MyServices({ className, mobileView, goToLink, ...rest }) {
  const classes = useStyles();

  return (
    <div
      style={{
        marginTop: 120,
        marginBottom: mobileView ? 20 : 106,
        zIndex: 10,
      }}
    >
      <div className="container">
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          className="service-view"
        >
          <div className={"service-title"}>Các Dịch Vụ Của Chúng Tôi</div>
          <div className={"service-content col-md-6"}>
            Công ty Nam Saigon luôn đặt khách hàng là trọng tâm để cung cấp dịch
            vụ chuyên nghiệp, tận tâm và tối ưu.
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row" style={{ marginTop: 24 }}>
          <div
            data-aos="flip-left"
            className="col-md-3 col-sm-6 col-6"
            style={{ marginTop: 16 }}
          >
            <div className="service-box my-service-ct">
              <img src="images/icon_service1.png" />
              <div className="service-box-title">Quản Lý, Vận hành BĐS</div>
            </div>
          </div>
          <div
            data-aos="flip-left"
            className="col-md-3 col-sm-6 col-6"
            style={{ marginTop: 16 }}
          >
            <div className="service-box my-service-ct">
              <img src="images/icon_service2.png" />
              <div className="service-box-title">Vệ sinh công nghiệp </div>
            </div>
          </div>
          <div
            data-aos="flip-left"
            className="col-md-3 col-sm-6 col-6"
            style={{ marginTop: 16 }}
          >
            <div className="service-box my-service-ct">
              <img src="images/icon_service3.png" />
              <div className="service-box-title">Chăm sóc cảnh quan</div>
            </div>
          </div>
          <div
            data-aos="flip-left"
            className="col-md-3 col-sm-6 col-6"
            style={{ marginTop: 16 }}
          >
            <div className="service-box my-service-ct">
              <img src="images/icon_service4.png" />
              <div className="service-box-title">Bảo trì, bảo dưỡng</div>
            </div>
          </div>
        </div>
        <div className="service-view">
          <div
            data-aos="flip-left"
            className={"button-see-more"}
            onClick={() => {
              goToLink("/dich-vu");
            }}
          >
            <div className="view-button-seemore">
              <span className={"title-box"}>Tìm hiểu thêm</span>
            </div>
            <img className="arrow-see-more2" src={arrow} />
          </div>
        </div>
      </div>
    </div>
  );
}

MyServices.propTypes = {
  className: PropTypes.string,
};

export default MyServices;
