import React, { useEffect, useCallback, useState } from "react";
import Page from "src/components/Page";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./index.css";
import imgNew from "src/assets/images/new4.png";
import icon_back from "src/assets/images/icon_back.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getProjectDetailRequest,
  getProjectRelateRequest,
} from "src/actions/projectActions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 128,
    paddingBottom: 128,
  },
  rootMb: {
    paddingTop: 48,
    paddingBottom: 128,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    // background: url("../img/intro-bg.jpg"),
    height: 560,
    backgroundImage: `url(images/bgjob.png)`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
}));
function ProjectDetailView(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [newDetail, setNewDetail] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [listNewsRelate, setListNewsRelate] = useState([]);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  useStyles();
  const getRelate = useCallback((pathSEO) => {
    dispatch(
      getProjectRelateRequest(
        pathSEO,
        (res) => {
          setListNewsRelate(res);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  }, []);
  const fetchData = useCallback((pathSEO) => {
    dispatch(
      getProjectDetailRequest(
        pathSEO,
        (res) => {
          setNewDetail(res);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  }, []);
  useEffect(() => {
    if (
      props.location.state !== undefined &&
      props.location.state.pathSEO !== ""
    ) {
      setNewDetail(props.location.state);
    } else {
      fetchData(window.location.pathname.split("/")[2]);
    }
    getRelate(window.location.pathname.split("/")[2]);
  }, [window.location.pathname]);
  useStyles();
  useEffect(() => {
    // alert("a");
  }, []);
  const handleClick = () => {
    history.goBack();
  };
  const handleClickItem = (item) => {
    history.push({
      pathname: "/du-an/" + item.pathSEO,
      state: item,
    });
  };
  return (
    <Page
      className={mobileView ? classes.rootMb : classes.root}
      title="Tin tức"
    >
      <div className="container">
        <div
          style={
            mobileView
              ? { marginRight: -15, marginLeft: -15 }
              : { paddingRight: "17.5%", paddingLeft: "17.5%" }
          }
        >
          <img className="img-header-detail" src={newDetail.thumbnail} />
          <div className="view-detail-top ">
            <div className="title-detai">{newDetail.name}</div>
            <div
              className="txt-date-content"
              style={{ marginTop: 24, marginLeft: 0 }}
            >
              {newDetail.address}
            </div>
            <div style={{ display: "flex", marginTop: 12 }}>
              <div className="txt-date-title">Diện tích khuôn viên đất : </div>
              <div className="txt-date-content">
                {newDetail.acreage} &#13217;
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 12 }}>
              <div className="txt-date-title">Khách hàng : </div>
              <div className="txt-date-content">{newDetail.partner}</div>
            </div>
            <div style={{ display: "flex", marginTop: 12 }}>
              <div className="txt-date-title">quy mô : </div>
              <div className="txt-date-content">{newDetail.scaleWorks}</div>
            </div>
          </div>
          <div className="view-detail">
            <div
              dangerouslySetInnerHTML={{
                __html: newDetail.body,
              }}
            ></div>
          </div>
          {newDetail.thumbnailPartner !== "" && (
            <div className="view-bottom">
              <div className="view-partner ">
                <div
                  className="avt-partner"
                  style={{
                    backgroundImage: `url(${newDetail.thumbnailPartner})`,
                    backgroundSize: "cover",
                  }}
                ></div>
                <div className="txt-comment">“{newDetail.commentPartner}"</div>
                <div className="txt-name-partner">
                  {newDetail.nameRepresentPartner}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="title-suggest">Dự Án Khác</div>
        <div className={"row"} style={{ marginTop: mobileView ? 0 : 40 }}>
          {listNewsRelate.length > 0 &&
            listNewsRelate.map((item) => {
              return (
                <div className="col-md-4">
                  {mobileView ? (
                    <div
                      className="view-project-bottom "
                      onClick={() => {
                        handleClickItem(item);
                      }}
                    >
                      <img
                        className="img-project-bottom"
                        src={item.thumbnail}
                      />

                      <div className="title-project-bottom">{item.name}</div>
                      {item.status === "1" ? (
                        <div className="view-status-done">Đã thực hiện</div>
                      ) : (
                        <div className="view-status">Đang thực hiện</div>
                      )}

                      <div className="txt-address-bottom">{item.address}</div>
                      <div className=" txt-scale-bottom ">
                        {item.scaleWorks}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="view-project-bottom "
                      onClick={() => {
                        handleClickItem(item);
                      }}
                    >
                      <img
                        className="img-project-bottom"
                        src={item.thumbnail}
                      />
                      <div className="view-title-project-top">
                        <div className="title-project-bottom">{item.name}</div>
                        {item.status === "1" ? (
                          <div className="view-status-done">Đã thực hiện</div>
                        ) : (
                          <div className="view-status">Đang thực hiện</div>
                        )}
                      </div>
                      <div className="txt-address-bottom">{item.address}</div>
                      <div className=" txt-scale-bottom ">
                        {item.scaleWorks}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {mobileView ? (
        <div
          style={{
            position: "fixed",
            top: 110,
            width: "100%",
          }}
        >
          <div className="container">
            <img
              onClick={handleClick}
              src={icon_back}
              className="icon-back-mb"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            top: 250,
            width: "100%",
          }}
        >
          <div className="container">
            <img
              onClick={handleClick}
              style={{ borderRadius: 50 }}
              src={icon_back}
              className="hover-ct"
            />
          </div>
        </div>
      )}
    </Page>
  );
}

export default ProjectDetailView;
