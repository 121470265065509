import axios from "src/utils/axios";
import highlightService from "src/services/highlightService";
export function createHighlightRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await highlightService.create(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function getHighlightRequest(onSuccess, onError) {
  return async (dispatch) => {
    try {
      const list = await highlightService.getList();
      onSuccess(list.data);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
export function editHighlightRequest(payload, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const user = await highlightService.editItem(payload);
      onSuccess(user);
    } catch (error) {
      onError(error);
      throw error;
    }
  };
}
