import jwtDecode from "jwt-decode";
import axios from "src/utils/axios";

class ContactService {
  create = (payload) =>
    new Promise((resolve, reject) => {
      console.log("payload", payload);
      axios
        .post("/contact/create", payload)
        .then((response) => {
          console.log(response);
          if (response.data.message === 1) {
            resolve(response.data.post);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getList = () =>
    new Promise((resolve, reject) => {
      axios
        .get("/contact/all")
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
}

const contactService = new ContactService();

export default contactService;
