import React, { useEffect, useState, useRef } from "react";
import Page from "src/components/Page";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./index.css";

import bgaboutus from "src/assets/images/bgaboutus.png";
import bgaboutus2 from "src/assets/images/bgaboutus2.png";
import bgaboutus2mb from "src/assets/images/bgaboutus2mb.png";
import bgaboutus3 from "src/assets/images/bgaboutus3.png";
import bgaboutus3mb from "src/assets/images/bgaboutus3mb.png";
import bgaboutus4 from "src/assets/images/bgaboutus4.png";
import giatricotloi1 from "src/assets/images/giatricotloi1.png";
import giatricotloi2 from "src/assets/images/giatricotloi2.png";
import giatricotloi3 from "src/assets/images/giatricotloi3.png";
import sumenh1 from "src/assets/images/sumenh1.png";
import sumenh2 from "src/assets/images/sumenh2.png";
import sumenh3 from "src/assets/images/sumenh3.png";
import sumenh4 from "src/assets/images/sumenh4.png";
import sumenh5 from "src/assets/images/sumenh5.png";
import sumenh6 from "src/assets/images/sumenh6.png";
import Scrollspy from "react-scrollspy";
import handleViewport from "react-in-viewport";
import bgservicemb from "src/assets/images/bgservicemb.png";
import arrowdown from "src/assets/images/arrowdown.png";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 133,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    // background: url("../img/intro-bg.jpg"),
    height: 560,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  introMb: {
    marginTop: 48,
    height: 240,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
}));
function AboutUsView() {
  const setting = useSelector((state) => state.setting?.aboutme);
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  useStyles();
  useEffect(() => {
    const setResponsiveness = () => {
      setWithView(window.innerWidth);
      return window.innerWidth < 1024
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const [withView, setWithView] = useState(window.innerWidth);
  const [inViewTop, setInViewTop] = useState(false);
  const [inViewMb, setInViewMb] = useState(true);
  const [openSort, setOpenSort] = useState(false);
  const sortRef = useRef(null);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const handleSortSelect = (value) => {
    setOpenSort(false);
  };

  const handleSortOpen = () => {
    setOpenSort(true);
  };

  const handleSortClose = () => {
    setOpenSort(false);
  };

  const Block = (props) => {
    const { inViewport, forwardedRef } = props;
    return (
      <div ref={forwardedRef}>
        <div
          className={`${classes.intro} border-image`}
          style={{ backgroundImage: `url(${setting?.banner || bgaboutus})` }}
        >
          <div className="container job-header">
            <div className="header-title" data-aos="zoom-in-up">
              Nam Saigon <br /> Chung tay kiến tạo
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Block1 = (props) => {
    const { inViewport, forwardedRef } = props;
    return (
      <div ref={forwardedRef}>
        <div
          className={`${classes.introMb} border-image`}
          style={{ backgroundImage: `url(${setting?.banner || bgaboutus})` }}
        >
          <div className="container job-header">
            <div className="header-title" data-aos="zoom-in-up">
              Nam Saigon <br /> Chung tay kiến tạo
            </div>
          </div>
        </div>
      </div>
    );
  };
  const ViewportBlock = handleViewport(Block);
  const ViewportBlockMb = handleViewport(Block1);
  return (
    <Page title="Về Nam Saigon">
      {/* <Hero /> */}
      <header id="header">
        <div style={{ background: "#fff" }}>
          {!mobileView ? (
            <ViewportBlock
              onEnterViewport={() => {
                console.log("onLeaveViewport setInViewMiddle true");
                setInViewTop(true);
              }}
              onLeaveViewport={() => {
                console.log("onLeaveViewport setInViewMiddle false");
                setInViewTop(false);
              }}
            />
          ) : (
            <ViewportBlockMb
              onEnterViewport={() => {
                console.log("onLeaveViewport setInViewMiddle true");
                setInViewMb(true);
              }}
              onLeaveViewport={() => {
                console.log("onLeaveViewport setInViewMiddle false");
                setInViewMb(false);
              }}
            />
          )}
          {!mobileView ? (
            <div className={inViewTop ? "" : "fixed-top"}>
              <div className="view-list-service " data-aos="zoom-in-up">
                <Scrollspy
                  className="list-service-content"
                  items={["section-1", "section-2", "section-3", "section-4"]}
                  currentClassName="isCurrent"
                >
                  <li className="item-service">
                    <a style={{ textDecoration: "none" }} href="#section-1">
                      Giới thiệu chung
                    </a>
                  </li>
                  <li className="item-service">
                    <a style={{ textDecoration: "none" }} href="#section-2">
                      Tầm nhìn
                    </a>
                  </li>
                  <li className="item-service">
                    <a style={{ textDecoration: "none" }} href="#section-3">
                      Sứ mệnh
                    </a>
                  </li>
                  <li className="item-service">
                    <a style={{ textDecoration: "none" }} href="#section-4">
                      Giá trị cốt lõi
                    </a>
                  </li>
                </Scrollspy>
              </div>
            </div>
          ) : (
            <div
              className={inViewMb ? "" : "fixed-top-mb"}
              onClick={handleSortOpen}
              ref={sortRef}
            >
              <Scrollspy
                className="view-service-mb"
                items={["section-1", "section-2", "section-3", "section-4"]}
                currentClassName="isCurrentMb"
              >
                <div
                  style={{ display: "flex", width: "100%", display: "none" }}
                >
                  <a
                    className="title-mb"
                    style={{ textDecoration: "none" }}
                    href="#section-1"
                  >
                    Giới thiệu chung
                  </a>
                  <img style={{ marginRight: 8 }} src={arrowdown} />
                </div>
                <div
                  style={{ display: "flex", width: "100%", display: "none" }}
                >
                  <a
                    className="title-mb"
                    style={{ textDecoration: "none" }}
                    href="#section-2"
                  >
                    Tầm nhìn
                  </a>
                  <img style={{ marginRight: 8 }} src={arrowdown} />
                </div>
                <div
                  style={{ display: "flex", width: "100%", display: "none" }}
                >
                  <a
                    className="title-mb"
                    style={{ textDecoration: "none" }}
                    href="#section-3"
                  >
                    Sứ mệnh
                  </a>
                  <img style={{ marginRight: 8 }} src={arrowdown} />
                </div>
                <div
                  style={{ display: "flex", width: "100%", display: "none" }}
                >
                  <a
                    className="title-mb"
                    style={{ textDecoration: "none" }}
                    href="#section-4"
                  >
                    Giá trị cốt lõi
                  </a>
                  <img style={{ marginRight: 8 }} src={arrowdown} />
                </div>
              </Scrollspy>
            </div>
          )}

          <section id="section-1">
            <div
              className="container"
              style={
                mobileView ? {} : { paddingRight: "8.5%", paddingLeft: "8.5%" }
              }
            >
              {mobileView ? (
                <div className="view-top">
                  <img className="img-about-top" src={setting?.image1} />
                  <div className="title-about">
                    Công ty Nam Saigon cung cấp đầy đủ dịch vụ quản lý, vận hành
                    chất lượng cao với bất kỳ loại hình BĐS như khu căn hộ, cao
                    ốc văn phòng, khu thương mại – dịch vụ, khu phức hợp, tổ hợp
                    công nghiệp…
                  </div>
                </div>
              ) : (
                <div className="view-top">
                  <div className="col-md-6" style={{ padding: 0 }}>
                    <img className="img-about-top" src={setting?.image1} />
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      paddingRight: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="title-about">
                      Công ty Nam Saigon cung cấp đầy đủ dịch vụ quản lý, vận
                      hành chất lượng cao với bất kỳ loại hình BĐS như khu căn
                      hộ, cao ốc văn phòng, khu thương mại – dịch vụ, khu phức
                      hợp, tổ hợp công nghiệp…
                    </div>
                  </div>
                </div>
              )}

              {mobileView ? (
                <div className="about-view2">
                  <div className="row">
                    <div
                      className="col-md-12 text-detail-view2"
                      style={{ marginRight: mobileView ? 64 : 0 }}
                    >
                      Dựa trên tôn chỉ chính kiến tạo sự văn minh & an toàn, Nam
                      Saigon mang lại dịch vụ phục vụ cư dân, khách hàng cảm
                      thấy thoải mái, an toàn nhất khi sử dụng BĐS với những
                      nhân sự có kinh nghiệm quản lý điều hành, chăm sóc khách
                      hàng lâu năm, chuyên nghiệp.
                    </div>
                    <div
                      className="col-md-12 text-detail-view2"
                      style={
                        mobileView ? { marginLeft: 64, marginTop: 32 } : {}
                      }
                    >
                      Bên cạnh đó, Nam Saigon cung cấp dịch vụ quản lý cơ sở vật
                      chất tiện nghi dựa vào đặc trưng của từng dự án bất động
                      sản mà đội ngũ chuyên nghiệp của Nam Saigon sẽ đưa ra
                      chiến lược khai thác phù hợp, hiệu quả và tối ưu chi phí
                      vận hành.
                    </div>
                  </div>
                </div>
              ) : (
                <div className="about-view2">
                  <div className="row">
                    <div
                      className="col-md-6 text-detail-view2"
                      style={{ marginRight: mobileView ? 64 : 0 }}
                    >
                      Dựa trên tôn chỉ chính kiến tạo sự văn minh & an toàn, Nam
                      Saigon mang lại dịch vụ phục vụ cư dân, khách hàng cảm
                      thấy thoải mái, an toàn nhất khi sử dụng BĐS với những
                      nhân sự có kinh nghiệm quản lý điều hành, chăm sóc khách
                      hàng lâu năm, chuyên nghiệp.
                    </div>
                    <div
                      className="col-md-6 text-detail-view2"
                      style={
                        mobileView ? { marginLeft: 64, marginTop: 32 } : {}
                      }
                    >
                      Bên cạnh đó, Nam Saigon cung cấp dịch vụ quản lý cơ sở vật
                      chất tiện nghi dựa vào đặc trưng của từng dự án bất động
                      sản mà đội ngũ chuyên nghiệp của Nam Saigon sẽ đưa ra
                      chiến lược khai thác phù hợp, hiệu quả và tối ưu chi phí
                      vận hành.
                    </div>
                  </div>
                </div>
              )}
            </div>
            {mobileView ? (
              <div
                data-aos="zoom-in-up"
                className="img-about-view3"
                style={{
                  backgroundImage: `url(${setting?.image2mb})`,
                }}
              >
                <div className="text-detail-view3">
                  Công ty Nam Sài Gòn tập trung vào đào tạo cũng như tái đào tạo
                  đội ngũ nhân lực làm việc trong các tòa nhà. Vì thế đảm bảo
                  tiêu chuẩn cao nhất ở mọi thời điểm trong mỗi tòa nhà mà chúng
                  tôi quản lý. Sự tham gia của Nam Sài Gòn có thể bắt đầu từ
                  giai đoạn dự án đang xây dựng cho đến khi bất động sản đã hoàn
                  thiện để đi vào khai thác.
                </div>
              </div>
            ) : (
              <div className="container">
                <div
                  data-aos="zoom-in-up"
                  className="img-about-view3"
                  style={{
                    backgroundImage: `url(${setting?.image2})`,
                    borderRadius: 0,
                  }}
                >
                  <div className="text-detail-view3">
                    Công ty Nam Sài Gòn tập trung vào đào tạo cũng như tái đào
                    tạo đội ngũ nhân lực làm việc trong các tòa nhà. Vì thế đảm
                    bảo tiêu chuẩn cao nhất ở mọi thời điểm trong mỗi tòa nhà mà
                    chúng tôi quản lý. Sự tham gia của Nam Sài Gòn có thể bắt
                    đầu từ giai đoạn dự án đang xây dựng cho đến khi bất động
                    sản đã hoàn thiện để đi vào khai thác.
                  </div>
                </div>
              </div>
            )}
          </section>
          <section id="section-2">
            <div className="about-view4">
              <div
                data-aos="zoom-in-up"
                className="img-about-view4"
                style={{
                  backgroundImage: `url(${
                    mobileView ? bgaboutus2mb : bgaboutus2
                  })`,
                }}
              >
                <div className="title-view5" style={{ marginTop: 55 }}>
                  TẦM NHÌN
                </div>
                <div className="title-view4">
                  Đến năm 2025, nằm trong top 15 các công ty cung cấp dịch vụ,
                  quản lý, vận hành toà nhà, có thị phần lớn nhất của TPHCM.
                </div>
              </div>
            </div>
          </section>
          <section id="section-3">
            <div className="about-view5">
              {mobileView ? (
                <div className="about-view5-content">
                  <div className="title-view5">SỨ MỆNH CỦA CHÚNG TÔI</div>
                  <div className={"container-new"}>
                    <div style={{ padding: 24 }}>
                      <img className="img-view5-small" src={setting?.image3} />
                      <div className="title-content-view5">
                        Đối với khách hàng
                      </div>
                      <div className="txt-content-view5 ">
                        Được trải nghiệm dịch vụ an toàn, chuyên nghiệp, thân
                        thiện với chi phí hợp lý.
                        <br /> Giúp bảo toàn, nâng cao giá trị BĐS.
                      </div>
                    </div>
                    <div style={{ padding: 24 }}>
                      <img className="img-view5-small" src={setting?.image4} />
                      <div className="title-content-view5">Đối với đối tác</div>
                      <div className="txt-content-view5 ">
                        Cùng nhau phát triển, hợp tác bền vững. Chia sẻ lợi ích
                        và trách nhiệm.
                      </div>
                    </div>
                    <img className="img-view5-big" src={setting?.image5} />
                  </div>
                  <div className={"container-new"}>
                    <div style={{ padding: 24 }}>
                      <img className="img-view5-small" src={setting?.image6} />
                      <div className="title-content-view5">
                        Đối với cán bộ, nhân viên
                      </div>
                      <div className="txt-content-view5 ">
                        Bình đẳng về cơ hội phát triển trong môi trường làm việc
                        có trách nhiệm, kiến thức, năng động, sáng tạo. Thu nhập
                        trên mức trung bình tại VN.
                      </div>
                    </div>
                    <div style={{ padding: 24 }}>
                      <img className="img-view5-small" src={setting?.image7} />
                      <div className="title-content-view5">Đối với xã hội</div>
                      <div className="txt-content-view5 ">
                        Ghi nhận năng lực của công ty dịch vụ quản lý, vận hành
                        BĐS VN. Góp phần kiến tạo văn hoá cộng đồng nhân văn.
                      </div>
                    </div>
                    <img className="img-view5-big" src={setting?.image8} />
                  </div>
                </div>
              ) : (
                <div className="about-view5-content">
                  <div className="title-view5">SỨ MỆNH CỦA CHÚNG TÔI</div>
                  <div
                    className={"container-new"}
                    style={{
                      display: "flex",
                      marginTop: 40,
                      paddingBottom: 80,
                      marginLeft:
                        withView > 1200
                          ? (withView - 1170) / 2
                          : withView > 970
                          ? (withView - 970) / 2
                          : withView > 768
                          ? (withView - 750) / 2
                          : 0,
                    }}
                  >
                    <div style={{ width: "27.5%", padding: 24 }}>
                      <img className="img-view5-small" src={setting?.image3} />
                      <div className="title-content-view5">
                        Đối với khách hàng
                      </div>
                      <div className="txt-content-view5 ">
                        Được trải nghiệm dịch vụ an toàn, chuyên nghiệp, thân
                        thiện với chi phí hợp lý.
                        <br /> Giúp bảo toàn, nâng cao giá trị BĐS.
                      </div>
                    </div>
                    <div style={{ width: "27.5%", padding: 24 }}>
                      <img className="img-view5-small" src={setting?.image4} />
                      <div className="title-content-view5">Đối với đối tác</div>
                      <div className="txt-content-view5 ">
                        Cùng nhau phát triển, hợp tác bền vững. Chia sẻ lợi ích
                        và trách nhiệm.
                      </div>
                    </div>
                    <div style={{ width: "45%", marginLeft: 32 }}>
                      <img className="img-view5-big" src={setting?.image5} />
                    </div>
                  </div>
                  <div
                    className={"container-new"}
                    style={{
                      display: "flex",
                      marginRight:
                        withView > 1200
                          ? (withView - 1170) / 2
                          : withView > 970
                          ? (withView - 970) / 2
                          : withView > 768
                          ? (withView - 750) / 2
                          : 0,
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <img className="img-view5-big" src={setting?.image6} />
                    </div>
                    <div style={{ width: "27.5%", padding: 24 }}>
                      <img className="img-view5-small" src={setting?.image7} />
                      <div className="title-content-view5">
                        Đối với cán bộ, nhân viên
                      </div>
                      <div className="txt-content-view5 ">
                        Bình đẳng về cơ hội phát triển trong môi trường làm việc
                        có trách nhiệm, kiến thức, năng động, sáng tạo. Thu nhập
                        trên mức trung bình tại VN.
                      </div>
                    </div>
                    <div style={{ width: "27.5%", padding: 24 }}>
                      <img className="img-view5-small" src={setting?.image8} />
                      <div className="title-content-view5">Đối với xã hội</div>
                      <div className="txt-content-view5 ">
                        Ghi nhận năng lực của công ty dịch vụ quản lý, vận hành
                        BĐS VN. Góp phần kiến tạo văn hoá cộng đồng nhân văn.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
          <section id="section-4">
            <div className="about-view6">
              <div className="title-view5">GIÁ TRỊ CỐT LÕI</div>
              {mobileView ? (
                <div
                  className="row"
                  style={{ justifyContent: "center", marginTop: 56 }}
                >
                  <div
                    style={{ width: "50%", textAlign: "center" }}
                    className="hover-view"
                  >
                    <img className="icon-value" src={giatricotloi1} />
                    <div className="txt-value">Công bằng</div>
                  </div>
                  <div
                    style={{ width: "50%", textAlign: "center" }}
                    className="hover-view"
                  >
                    <img className="icon-value" src={giatricotloi2} />
                    <div className="txt-value">Trung Thực</div>
                  </div>
                  <div className="hover-view" style={{ marginTop: 24 }}>
                    <img className="icon-value" src={giatricotloi3} />
                    <div className="txt-value">Trí Tuệ</div>
                  </div>
                </div>
              ) : (
                <div
                  className="row-ct"
                  style={{ justifyContent: "center", marginTop: 56 }}
                >
                  <div className="hover-view">
                    <img className="icon-value" src={giatricotloi1} />
                    <div className="txt-value">Công bằng</div>
                  </div>
                  <div className="hover-view">
                    <img className="icon-value" src={giatricotloi2} />
                    <div className="txt-value">Trung Thực</div>
                  </div>
                  <div className="hover-view">
                    <img className="icon-value" src={giatricotloi3} />
                    <div className="txt-value">Trí Tuệ</div>
                  </div>
                </div>
              )}
              <div className="txt-end">
                Sự đánh giá của số đông khách hàng là
                <br /> thước đo về chất lượng.
              </div>
            </div>
          </section>
        </div>
      </header>
    </Page>
  );
}

export default AboutUsView;
