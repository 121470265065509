import React, { useEffect, useCallback, useState } from "react";
import Page from "src/components/Page";
import "./index.css";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  makeStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getJobRequest } from "src/actions/jobActions";
import { createContactRequest } from "src/actions/contactActions";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@material-ui/lab";
import icon_search from "src/assets/images/icon_search.png";
import arrow from "src/assets/images/arrow.png";
import bgjob1 from "src/assets/images/bgjob1.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import bgjob from "src/assets/images/bgjob.png";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 133,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    // background: url("../img/intro-bg.jpg"),
    height: 560,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  introMb: {
    // background: url("../img/intro-bg.jpg"),
    height: 375,

    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#DA9E26",
    },
  },
}));
const paymentStatusColors = {
  canceled: "error",
  pending: "warning",
  completed: "success",
  rejected: "error",
};

function JobsView() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [listJob, setListJob] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const [dataContact, setDataContact] = useState({
    name: "",
    email: "",
    note: "",
  });
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const submit = () => {
    if (dataContact.name !== "" && dataContact.name !== "email") {
      setIsLoading(true);
      let object = {
        type: 1,
        body:
          "Cần tư vấn nghề nghiệp :  Tên : " +
          dataContact.name +
          " ;Email : " +
          dataContact.email +
          " ; Lời nhắn : " +
          dataContact.note,
      };
      dispatch(
        createContactRequest(
          object,
          (res) => {
            setOpen(true);
            setDataContact({
              name: "",
              email: "",
              note: "",
            });
            setMessage(
              "Quý khách đã gửi thông tin thành công, NSG sẽ liên hệ trong thời gian sớm nhất!"
            );
          },
          (error) => {
            setOpen(true);
            setMessage("Đã có lỗi xảy ra, vui lòng thử lại!");
          }
        )
      );
    } else {
      setMessage("Vui lòng nhập đầy đủ thông tin");
      setOpen(true);
    }
  };
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
    setValue("");
  };
  useEffect(() => {
    getJobs();
  }, []);
  const getJobs = useCallback(() => {
    dispatch(
      getJobRequest(
        (res) => {
          setListJob(res);
        },
        (error) => {}
      )
    );
  }, []);

  useStyles();
  const handleClick = (item) => {
    history.push({
      pathname: "/nghe-nghiep/" + item.pathSEO,
      state: item,
    });
  };
  const setting = useSelector((state) => state.setting?.job);
  return (
    <Page title="Nghề nghiệp">
      {/* <Hero /> */}
      <header style={{ background: "#fff" }} id="header">
        <div
          className={`${
            mobileView ? classes.introMb : classes.intro
          } border-image`}
          style={{ backgroundImage: `url(${setting?.banner})` }}
        >
          <div className="container job-header">
            <div className="header-title" data-aos="zoom-in-up">
              Cơ hội việc làm phù hợp <br />
              với bạn tại Nam Saigon
            </div>
          </div>
        </div>
        <div
          className="container"
          style={
            mobileView ? {} : { paddingRight: "8.5%", paddingLeft: "8.5%" }
          }
        >
          {/* <PerfectScrollbar style={{ overflowX: "hidden" }}> */}
          <div className="search-view-container">
            <div className="search-view ">
              <img src={icon_search} className="icon-arrow " />
              <input
                className="input-search"
                placeholder={"Bạn đang tìm công việc gì?"}
                type="text"
                // value={this.state.value}
                // onChange={this.handleChange}
              />
            </div>
          </div>
          {!mobileView ? (
            <Box>
              <Table style={{ marginTop: 56, overflowX: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <td
                      className="col-md-1 title-header-table "
                      style={{ paddingLeft: 10, textAlign: "left" }}
                    >
                      STT
                    </td>
                    <td
                      className="col-md-3 title-header-table "
                      style={{ paddingLeft: 0, textAlign: "left" }}
                    >
                      Chức vụ
                    </td>
                    <td className="col-md-2 title-header-table ">Trình độ</td>
                    <td className="col-md-2 title-header-table ">Số lượng</td>
                    <td className="col-md-2 title-header-table ">Ngày đăng</td>
                    <td
                      className="col-md-2 title-header-table "
                      style={{ paddingRight: 10, textAlign: "right" }}
                    >
                      Tình trạng
                    </td>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listJob
                    .slice((currentPage - 1) * 9, currentPage * 9)
                    .map((item, index) => {
                      return (
                        <TableRow
                          className="table-row hover-ct-row"
                          key={item.id}
                          onClick={() => {
                            handleClick(item);
                          }}
                          // selected={selectedOrders.indexOf(order.id) !== -1}
                        >
                          <td style={{ textAlign: "left", paddingLeft: 10 }}>
                            {(currentPage - 1) * 9 + index + 1}
                          </td>
                          <td>
                            <div
                              style={{ textAlign: "left" }}
                              className="title-position"
                            >
                              {item.position}
                            </div>
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <div className="title-level">{item.level}</div>
                            </div>
                          </td>
                          <td> {item.count}</td>
                          <td>
                            {moment(Number.parseFloat(item.datePosted)).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td style={{ textAlign: "right", paddingRight: 10 }}>
                            <div style={{ display: "flex" }}>
                              {item.status === 0 ? (
                                <div className="job-close">Đã đóng</div>
                              ) : (
                                <div className="job-open">Còn mở</div>
                              )}
                            </div>
                          </td>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <div style={{ marginTop: 24 }}>
              {listJob.map((item) => {
                return (
                  <div
                    className="item-mb"
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    <div className="title-position-mb">{item.position}</div>
                    <div className="row-ct">
                      <div className="title-time-mb">SL : {item.count}</div>
                      <div className="title-time-mb" style={{ marginLeft: 24 }}>
                        Ngày đăng :{" "}
                        {moment(Number.parseFloat(item.datePosted)).format(
                          "DD/MM/YYY"
                        )}
                      </div>
                      {item.status === 0 ? (
                        <div className="job-close">Đã đóng</div>
                      ) : (
                        <div className="job-open">Còn mở</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {!mobileView && listJob.length > 10 && (
          <div className="page-view">
            <Pagination
              size="large"
              classes={{ ul: classes.ul }}
              count={(listJob.length - (listJob.length % 9)) / 9 + 1}
              onChange={handleChange}
              page={currentPage}
            />
          </div>
        )}
        <div className="container" style={{ marginTop: 36, marginBottom: 98 }}>
          <div
            style={{
              height: 540,
              borderRadius: 8,
              backgroundImage: `url(${setting?.banner2})`,
              backgroundSize: "cover",
            }}
          >
            <div className="bg-contact-job">
              <div>
                <div
                  style={
                    mobileView ? {} : { paddingLeft: 80, paddingRight: 30 }
                  }
                  className="col-md-6 col-sm-12"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="contact-title-job">
                    Công việc bạn đang cần chưa tuyển dụng?
                  </div>
                  <div className="contact-content-job">
                    Gửi ngay thông tin nhu cầu việc làm của bạn, chúng tôi sẽ
                    liên hệ ngay!
                  </div>
                </div>
                <div
                  style={{ paddingRight: mobileView ? 15 : 80 }}
                  className="col-md-6 col-sm-12"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <input
                    className="input-contact"
                    placeholder={"Họ và tên"}
                    type="text"
                    value={dataContact.name}
                    onChange={(event) => {
                      setDataContact({
                        ...dataContact,
                        name: event.target.value,
                      });
                    }}
                  />

                  <input
                    className="input-contact"
                    placeholder={"Email"}
                    type="text"
                    value={dataContact.email}
                    onChange={(event) => {
                      setDataContact({
                        ...dataContact,
                        email: event.target.value,
                      });
                    }}
                  />
                  <textarea
                    style={{ height: 120 }}
                    className="input-contact"
                    placeholder={"Lời nhắn"}
                    type="text"
                    value={dataContact.note}
                    onChange={(event) => {
                      setDataContact({
                        ...dataContact,
                        note: event.target.value,
                      });
                    }}
                  />
                  {isLoading ? (
                    <div className="button-see-more-job">
                      <CircularProgress value={10} size={20} />
                    </div>
                  ) : (
                    <div className="button-see-more-job" onClick={submit}>
                      <div className="view-button-seemore">
                        <span className={"title-box"}>Tư vấn công việc</span>
                      </div>
                      <img className="arrow-see-more" src={arrow} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Thông báo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default JobsView;
