import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Box, Toolbar, Link, makeStyles } from "@material-ui/core";
import "./TopBar.css";
import logoNSG from "../../assets/images/logo.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    backgroundColor: "#fff",
  },
  rootBlur: {
    // backgroundColor: theme.palette.background.default,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  toolbar: {
    height: 80,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

function TopBar({ className, headersData, ...rest }) {
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();
  const history = useHistory();

  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
    currentPath: "",
  });
  const [title, setTitle] = useState("");
  const [blurTopBar, setBlurTopBar] = useState(false);
  const [headerChecked, setHeaderChecked] = useState(false);
  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    const handleScroll = (scroll) => {
      if (window.pageYOffset > 900) {
        setBlurTopBar(true);
      } else {
        setBlurTopBar(false);
      }
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
      window.addEventListener("scroll", handleScroll);
    };
  }, []);
  const getTitle = (pathName) => {
    headersData.forEach((element) => {
      if (element.href.indexOf(pathName) > -1) {
        return setTitle(element.label);
      }
    });
  };
  useEffect(() => {
    let pathname = window.location.pathname;
    if (window.location.pathname === "/") {
      pathname = "/trang-chu";
    }
    if (pathname !== "") {
      getTitle(pathname);
    }
    setState((prevState) => ({
      ...prevState,
      currentPath: pathname,
    }));
  }, [window.location.pathname]);
  const onClickLink = (item) => {
    let check = document.getElementById("navi-toggle");
    if (check) check.checked = false;
    setHeaderChecked(false);
    history.push(item.href);
  };
  const onClickLogo = () => {
    history.push("");
  };
  const onChangeHandle = (event) => {
    console.log(event.target.checked);
    setHeaderChecked(event.target.checked);
  };
  const displayMobile = () => {
    return (
      <div class="navigation">
        <input
          type="checkbox"
          class="navigation__checkbox"
          id="navi-toggle"
          onChange={onChangeHandle}
        />
        <label for="navi-toggle" class="navigation__button">
          <span class="navigation__icon"></span>
        </label>
        <div class="navigation__background">&nbsp;</div>

        <nav class="navigation__nav">
          {headerChecked === true && (
            <ul class="navigation__list">
              <div
                style={{ height: 48, backgroundColor: "#fff", paddingTop: 14 }}
                className="top-bar title-pathName"
              >
                Mục lục
              </div>
              <img
                onClick={onClickLogo}
                style={{
                  width: 53,
                  height: 64,
                  position: "absolute",
                  top: 14,
                  left: 0,
                  marginLeft: 24,
                  zIndex: 100,
                }}
                src={logoNSG}
              />
              <li style={{ height: 30 }}></li>
              {headersData.map((item) => {
                let check =
                  state.currentPath.indexOf(item.href) > -1 ? true : false;
                return (
                  <li class="navigation__item ">
                    <div
                      class={
                        check
                          ? "navigation__link active-mb"
                          : "navigation__link"
                      }
                      onClick={() => {
                        onClickLink(item);
                      }}
                    >
                      {item.label}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </nav>
      </div>
    );
  };
  return (
    <AppBar
      className={clsx(blurTopBar ? classes.rootBlur : classes.root, className)}
      color="default"
      {...rest}
    >
      {!mobileView ? (
        <Toolbar className={classes.toolbar + " top-bar"}>
          {/* <RouterLink to="/"> */}
          <img
            onClick={onClickLogo}
            style={{
              width: 98,
              height: 119,
              position: "absolute",
              top: 14,
              marginLeft: 24,
            }}
            src={logoNSG}
          />
          {/* </RouterLink> */}
          <Box flexGrow={1} />
          {headersData.map((item) => {
            let check =
              state.currentPath.indexOf(item.href) > -1 ? true : false;
            return (
              <div
                className={check ? "view-link-item active" : "view-link-item"}
              >
                <Link
                  className={check ? "title-active link-item " : "link-item "}
                  component={RouterLink}
                  to={item.href}
                >
                  {item.label}
                </Link>
              </div>
            );
          })}
        </Toolbar>
      ) : (
        <div style={{ backgroundColor: "#fff", height: 48 }}>
          <img
            onClick={onClickLogo}
            style={{
              width: 53,
              height: 64,
              position: "absolute",
              top: 14,
              marginLeft: 24,
              zIndex: 100,
            }}
            src={logoNSG}
          />
          <div
            className="title-pathName"
            style={{ textAlign: "center", marginTop: 14 }}
          >
            {title}
          </div>
          {displayMobile()}
        </div>
      )}
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
