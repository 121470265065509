import jwtDecode from "jwt-decode";
import axios from "src/utils/axios";

class JobService {
  create = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .post("/job/create", payload)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data.post);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getList = () =>
    new Promise((resolve, reject) => {
      axios
        .get("/job/jobs")
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getDetail = (path) =>
    new Promise((resolve, reject) => {
      axios
        .get("/job/detail/" + path)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  editItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .put("/job/edit?itemID=" + payload.id, payload)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  deleteItem = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .delete("/job/delete?itemID=" + payload.id)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  getListOpenRelate = (path) =>
    new Promise((resolve, reject) => {
      axios
        .get("/job/openrelate/" + path)
        .then((response) => {
          if (response.data.message === 1) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
}

const jobService = new JobService();

export default jobService;
