import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import TopBar from "./TopBar";
import Footer from "./Footer";
import { getInfomationRequest } from "src/actions/infomationActions";
import { useDispatch } from "react-redux";
const headersData = [
  {
    label: "Trang chủ",
    href: "/trang-chu",
  },
  {
    label: "Về Nam Saigon",
    href: "/ve-namsaigon",
  },
  {
    label: "Dịch vụ",
    href: "/dich-vu",
  },
  {
    label: "Dự án",
    href: "/du-an",
  },
  {
    label: "Tin tức",
    href: "/tin-tuc",
  },
  {
    label: "Nghề nghiệp",
    href: "/nghe-nghiep",
  },
  {
    label: "Liên hệ",
    href: "/lien-he",
  },
];
const useStyles = makeStyles((theme) => ({
  // root: {
  //   backgroundColor: theme.palette.background.default,
  //   display: "flex",
  //   height: "100%",
  //   // overflow: "hidden",
  //   width: "100%",
  //   // overflow-x: hidden;
  //   // overflowX: "hidden",
  // },
  // wrapper: {
  //   display: "flex",
  //   flex: "1 1 auto",
  //   // overflow: "hidden",
  //   paddingTop: 80,
  // },
  // contentContainer: {
  //   display: "flex",
  //   flex: "1 1 auto",
  //   // overflow: "hidden",
  // },
  // content: {
  //   flex: "1 1 auto",
  //   height: "100%",
  //   // overflow: "auto",
  // },
}));

function MainLayout({ children }) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const getProjects = () => {
    dispatch(
      getInfomationRequest(
        (res) => {
          console.log("getInfomationRequest", res);
        },
        (error) => {
          console.log("error", error);
        }
      )
    );
  };

  useEffect(() => {
    getProjects();
  }, []);
  return (
    <div className={classes.root}>
      <TopBar headersData={headersData} />
      <div style={{ background: "#F5F5F5" }} className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      <Footer headersData={headersData} />
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
};

export default MainLayout;
