import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import handleViewport from "react-in-viewport";
import { useSelector } from "react-redux";
import Scrollspy from "react-scrollspy";
import arrowdown from "src/assets/images/arrowdown.png";
import bgservice from "src/assets/images/bgservice.png";
import bgservicemb from "src/assets/images/bgservicemb.png";
import service4 from "src/assets/images/service4.png";
import service5 from "src/assets/images/service5.png";
import Page from "src/components/Page";
import "./index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 133,
  },
  browseButton: {
    marginLeft: theme.spacing(2),
  },
  intro: {
    // background: url("../img/intro-bg.jpg"),
    height: 560,
    // backgroundImage: `url(${bgservice})`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  introMb: {
    // background: url("../img/intro-bg.jpg"),
    marginTop: 48,
    height: 240,
    // backgroundImage: `url(${bgservicemb})`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
}));
function ServiceView() {
  const classes = useStyles();
  const service2Ref = useRef(null);
  const goToByScroll = (id) => {
    service2Ref.current.scrollIntoView();
  };
  useStyles();
  const [inViewTop, setInViewTop] = useState(false);
  const [inViewMb, setInViewMb] = useState(true);
  const [mobileView, setMobileView] = useState(false);
  const [selectedSort, setSelectedSort] = useState("Most popular");
  const [openSort, setOpenSort] = useState(false);
  const sortRef = useRef(null);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const [withView, setWithView] = useState(window.innerWidth);
  const handleSortSelect = (value) => {
    setSelectedSort(value);
    setOpenSort(false);
  };

  const handleSortOpen = () => {
    setOpenSort(true);
  };

  const handleSortClose = () => {
    setOpenSort(false);
  };
  const setting = useSelector((state) => state.setting?.service);
  const Block = (props) => {
    const { inViewport, forwardedRef } = props;
    return (
      <div ref={forwardedRef}>
        <div
          className={`${classes.intro} border-image`}
          style={{ backgroundImage: `url(${setting?.banner})` }}
        >
          <div className="container job-header">
            <div className="header-title" data-aos="zoom-in-up">
              Các loại dịch vụ tại <br /> Nam SaiGon
            </div>
          </div>
        </div>
      </div>
    );
  };
  const BlockMb = (props) => {
    const { inViewport, forwardedRef } = props;
    return (
      <div ref={forwardedRef}>
        <div
          className={`${classes.introMb} border-image`}
          style={{ backgroundImage: `url(${setting?.bannermb})` }}
        >
          <div className="container job-header">
            <div className="header-title" data-aos="zoom-in-up">
              Các loại dịch vụ tại <br /> Nam SaiGon
            </div>
          </div>
        </div>
      </div>
    );
  };
  const ViewportBlock = handleViewport(Block);
  const ViewportBlockMb = handleViewport(BlockMb);

  return (
    <Page title="Dịch vụ">
      {/* <Hero /> */}
      <header id="header">
        <div style={{ background: "#F5F5F5" }}>
          {!mobileView ? (
            <ViewportBlock
              onEnterViewport={() => {
                console.log("onLeaveViewport setInViewMiddle true");
                setInViewTop(true);
              }}
              onLeaveViewport={() => {
                console.log("onLeaveViewport setInViewMiddle false");
                setInViewTop(false);
              }}
            />
          ) : (
            <ViewportBlockMb
              onEnterViewport={() => {
                console.log("onLeaveViewport setInViewMiddle true");
                setInViewMb(true);
              }}
              onLeaveViewport={() => {
                console.log("onLeaveViewport setInViewMiddle false");
                setInViewMb(false);
              }}
            />
          )}
          {!mobileView ? (
            <div className={inViewTop ? "" : "fixed-top"}>
              <div className="view-list-service ">
                {/* <Features /> */}
                <Scrollspy
                  className="list-service-content"
                  items={["section-1", "section-2", "section-3", "section-4"]}
                  currentClassName="isCurrent"
                >
                  <li className="item-service">
                    <a style={{ textDecoration: "none" }} href="#section-1">
                      Quản lý, vận hành BĐS
                    </a>
                  </li>
                  <li className="item-service">
                    <a style={{ textDecoration: "none" }} href="#section-2">
                      Vệ sinh công nghiệp
                    </a>
                  </li>
                  <li className="item-service">
                    <a style={{ textDecoration: "none" }} href="#section-3">
                      Chăm sóc cảnh quan
                    </a>
                  </li>
                  <li className="item-service">
                    <a style={{ textDecoration: "none" }} href="#section-4">
                      Bảo trì, bảo dưỡng
                    </a>
                  </li>
                </Scrollspy>
              </div>
            </div>
          ) : (
            <div
              className={inViewMb ? "" : "fixed-top-mb"}
              onClick={handleSortOpen}
              ref={sortRef}
            >
              <Scrollspy
                className="view-service-mb"
                items={["section-1", "section-2", "section-3", "section-4"]}
                currentClassName="isCurrentMb"
              >
                <div
                  style={{ display: "flex", width: "100%", display: "none" }}
                >
                  <a
                    className="title-mb"
                    style={{ textDecoration: "none" }}
                    href="#section-1"
                  >
                    Quản lý, vận hành BĐS
                  </a>
                  <img style={{ marginRight: 8 }} src={arrowdown} />
                </div>
                <div
                  style={{ display: "flex", width: "100%", display: "none" }}
                >
                  <a
                    className="title-mb"
                    style={{ textDecoration: "none" }}
                    href="#section-2"
                  >
                    Vệ sinh công nghiệp
                  </a>
                  <img style={{ marginRight: 8 }} src={arrowdown} />
                </div>
                <div
                  style={{ display: "flex", width: "100%", display: "none" }}
                >
                  <a
                    className="title-mb"
                    style={{ textDecoration: "none" }}
                    href="#section-3"
                  >
                    Chăm sóc cảnh quan
                  </a>
                  <img style={{ marginRight: 8 }} src={arrowdown} />
                </div>
                <div
                  style={{ display: "flex", width: "100%", display: "none" }}
                >
                  <a
                    className="title-mb"
                    style={{ textDecoration: "none" }}
                    href="#section-4"
                  >
                    Bảo trì, bảo dưỡng
                  </a>
                  <img style={{ marginRight: 8 }} src={arrowdown} />
                </div>
              </Scrollspy>
            </div>
          )}

          <div
            className="container"
            style={{ marginTop: !mobileView ? 80 : 24 }}
          >
            <section id="section-1">
              {!mobileView ? (
                <div className={"row"}>
                  <div
                    data-aos="zoom-in-up"
                    className="col-md-6"
                    style={{ padding: 0 }}
                  >
                    <img
                      className="icon-view3 "
                      src="images/icon_service1.png"
                    />
                    <div className="title-service ">Quản lý, vận hành BĐS </div>
                    <div
                      className="content-service"
                      style={{ marginRight: 80 }}
                    >
                      Công ty Nam Saigon (NSG) cung cấp đầy đủ các dịch vụ quản
                      lý vận hành cho nhiều loại hình BĐS tại Việt Nam như: Khu
                      căn hộ chưng cư, cao ốc văn phòng, khu thương mại -dịch
                      vụ, khu phức hợp, tổ hợp công nghiệp…
                    </div>
                  </div>
                  <div
                    data-aos="zoom-in-up"
                    className="col-md-6"
                    style={{ padding: 0 }}
                  >
                    <img
                      className="img-service border-1"
                      src={setting?.image1}
                    />
                  </div>
                </div>
              ) : (
                <div className={"row"}>
                  <div
                    className="col-md-6"
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      marginBottom: 24,
                    }}
                  >
                    <img
                      className="icon-view3 "
                      src="images/icon_service1.png"
                    />
                    <div className="title-service ">Quản lý, vận hành BĐS </div>
                    <div className="content-service">
                      Công ty NSG cung cấp đầy đủ các dịch vụ quản lý vận hành
                      cho nhiều loại hình BĐS tại Vn như: Khu căn hộ chưng cư,
                      cao ốc văn phòng, khu thương mại -dịch vụ, khu phức hợp,
                      tổ hợp công nghiệp…
                    </div>
                  </div>
                  <div className="col-md-6" style={{ padding: 0 }}>
                    <img
                      className="img-service border-1"
                      src={setting?.image1}
                    />
                  </div>
                </div>
              )}

              {!mobileView ? (
                <div className={"row"}>
                  <div
                    data-aos="zoom-in-up"
                    className="col-md-6"
                    style={{ padding: 0 }}
                  >
                    <img
                      className="img-service border-2"
                      src={setting?.image2}
                    />
                  </div>
                  <div
                    data-aos="zoom-in-up"
                    className="col-md-6"
                    style={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 224,
                    }}
                  >
                    <div className="content-service" style={{ marginLeft: 80 }}>
                      Nam Saigon không ngừng nỗ lực cung cấp các giải pháp toàn
                      diện nhất nhằm bảo vệ và tối đa hoá giá trị tài sản trong
                      khi giảm thiểu được chi phí và giúp khách hàng yên tâm hơn
                      với tài sản, môi trường sống của mình.
                    </div>
                  </div>
                </div>
              ) : (
                <div className={"row"}>
                  <div
                    className="col-md-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 24,
                    }}
                  >
                    <div className="content-service" style={{}}>
                      Nam Saigon không ngừng nỗ lực cung cấp các giải pháp toàn
                      diện nhất nhằm bảo vệ và tối đa hoá giá trị tài sản trong
                      khi giảm thiểu được chi phí và giúp khách hàng yên tâm hơn
                      với tài sản, môi trường sống của mình.
                    </div>
                  </div>
                  <div className="col-md-6" style={{ padding: 0 }}>
                    <img
                      className="img-service border-2"
                      src={setting?.image2}
                    />
                  </div>
                </div>
              )}
              {!mobileView ? (
                <div className={"row"}>
                  <div
                    data-aos="zoom-in-up"
                    className="col-md-6"
                    style={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 224,
                    }}
                  >
                    <div
                      className="content-service"
                      style={{ marginRight: 80 }}
                    >
                      Tuỳ thuộc vào quy mô và loại hình BĐS của bạn, Nam Saigon
                      sẽ đưa ra các chiến lược quản lý và giải pháp phù hợp
                      nhất. Sự tham gia của NSG có thể bắt đầu từ giai đoạn lập
                      dự toán, xây dựng phương án quản lý dự án đang xây dựng
                      hoặc khi BĐS đã hoàn thiện và đang được khai thác.
                    </div>
                  </div>
                  <div
                    data-aos="zoom-in-up"
                    className="col-md-6"
                    style={{ padding: 0 }}
                  >
                    <img
                      className="img-service border-3"
                      src={setting?.image3}
                    />
                  </div>
                </div>
              ) : (
                <div className={"row"}>
                  <div
                    className="col-md-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 24,
                    }}
                  >
                    <div className="content-service">
                      Tuỳ thuộc vào quy mô và loại hình BĐS của bạn, Nam Saigon
                      sẽ đưa ra các chiến lược quản lý và giải pháp phù hợp
                      nhất. Sự tham gia của NSG có thể bắt đầu từ giai đoạn lập
                      dự toán, xây dựng phương án quản lý dự án đang xây dựng
                      hoặc khi BĐS đã hoàn thiện và đang được khai thác.
                    </div>
                  </div>
                  <div className="col-md-6" style={{ padding: 0 }}>
                    <img
                      className="img-service border-3"
                      src={setting?.image3}
                    />
                  </div>
                </div>
              )}
            </section>
          </div>
          <div className="view2">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img className="img-view2" src="images/icon_service5.png" />
              <div className="txt-view2 col-md-7">
                Nam Saigon cam kết mang đến dịch vụ quản lý, vận hành chuyên
                nghiệp và hiệu quả. Chúng tôi tin rằng đây chính là nền tảng cho
                sự thành công và duy trì mối quan hệ hợp tác với khách hàng.
              </div>
            </div>
          </div>
          <section id="section-2">
            <div
              className="container"
              data-aos="zoom-in-up"
              style={
                !mobileView
                  ? { marginTop: 80 }
                  : {
                      padding: 0,
                      marginTop: 80,
                    }
              }
            >
              <img
                className="img-view3"
                src={setting?.image4}
                ref={service2Ref}
              />
              <div
                id="asdjkasd"
                className="view3-content"
                onScroll={() => {
                  console.log("onScroll img");
                }}
              >
                <img className="icon-view3" src="images/icon_service2.png" />
                <div className="title-view3-content">Vệ sinh công nghiệp</div>
                <div className="text-view3-content">
                  Công ty Nam Saigon cung cấp dịch vụ Vệ sinh công nghiệp với
                  trang thiết bị vệ sinh công nghiệp hiện đại, hóa chất an toàn
                  và nhân sự được đào tạo chuyên nghiệp.
                  <br /> <br /> Đáp ứng nhu cầu làm sạch với tiêu chuẩn hợp lý,
                  rõ ràng giúp khách hàng hoàn toàn thoải mái khi được sống
                  trong môi trường tốt, trong lành.
                </div>
              </div>
            </div>
          </section>
          <section id="section-3">
            {!mobileView ? (
              <div className="view4">
                <div
                  // className={"container-new"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // marginTop: 40,
                    // paddingBottom: 80,
                    marginLeft:
                      withView > 1200
                        ? (withView - 1170) / 2
                        : withView > 970
                        ? (withView - 970) / 2
                        : withView > 768
                        ? (withView - 750) / 2
                        : 0,
                  }}
                >
                  <div
                    className="col-md-5"
                    // style={{ display: "flex", flexDirection: "column" }}
                  >
                    <img
                      className="icon-view3"
                      src="images/icon_service2.png"
                    />
                    <div className="title-view3-content">
                      Chăm sóc cảnh quan
                    </div>
                    <div
                      className="text-view3-content"
                      style={{ marginLeft: 0 }}
                    >
                      Công ty Nam Saigon cung cấp dịch vụ chăm sóc cảnh quan với
                      đội ngũ nhân viên chuyên nghiệp, không ngừng nâng cao kiến
                      thức tay nghề qua các chương trình đào tạo kiến thức, mang
                      đến môi trường sống trong lành khoẻ mạnh cho người dân tại
                      các duy trì, bảo dưỡng, cải tạo cây xanh trong suốt nhiều
                      năm.
                    </div>
                  </div>
                  <div className="col-md-7 row-ct" style={{ padding: 0 }}>
                    <div style={{ width: "50%", marginLeft: 32 }}>
                      <img className="img-view4" src={service4} />
                    </div>
                    <div style={{ width: "50%", marginLeft: 32 }}>
                      <img className="img-view4" src={service5} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <div
                  className="col-md-12"
                  // style={{ display: "flex", flexDirection: "column" }}
                >
                  <img className="icon-view3" src="images/icon_service2.png" />
                  <div className="title-view3-content">Chăm sóc cảnh quan</div>
                  <div className="text-view3-content" style={{ marginLeft: 0 }}>
                    Công ty Nam Saigon cung cấp dịch vụ chăm sóc cảnh quan với
                    đội ngũ nhân viên chuyên nghiệp, không ngừng nâng cao kiến
                    thức tay nghề qua các chương trình đào tạo kiến thức, mang
                    đến môi trường sống trong lành khoẻ mạnh cho người dân tại
                    các duy trì, bảo dưỡng, cải tạo cây xanh trong suốt nhiều
                    năm.
                  </div>
                </div>
                {/* <div className="col-md-6 " style={{ padding: 0 }}>
                <div className="row">
                  <div className="col-md-6 ">
                    <img className="img-view4" src={service4} />
                  </div>
                  <div className="col-md-6 ">
                    <img className="img-view4" src={service5} />
                  </div>
                </div>
              </div> */}
                <div className="col-md-12 row-ct" style={{ padding: 0 }}>
                  <div style={{ width: "80%", marginLeft: 15 }}>
                    <img className="img-view4" src={setting?.image5} />
                  </div>
                  <div style={{ width: "20%", marginLeft: 15 }}>
                    <img className="img-view4" src={setting?.image6} />
                  </div>
                </div>
              </div>
            )}
          </section>
          <section id="section-4">
            <div
              className="container"
              data-aos="zoom-in-up"
              style={
                !mobileView
                  ? { marginTop: 80 }
                  : {
                      padding: 0,
                      marginTop: 80,
                    }
              }
            >
              <img className="img-view3" src={setting?.image7} />
              <div className="view3-content">
                <img className="icon-view3" src="images/icon_service4.png" />
                <div className="title-view3-content">Bảo trì, bảo dưỡng</div>
                <div className="text-view3-content">
                  Chất lượng là ưu tiên hàng đầu của công ty, NSG sử dụng đội
                  ngũ kỹ thuật có kinh nghiệm nhiều năm. <br /> <br /> Mục tiêu
                  tiên quyết của NSG trong việc quản lý hệ thống kĩ thuật toà
                  nhà là giảm thiểu rủi ro, chi phí và tiêu chuẩn hoá quy trình
                  vận hành, bảo trì, nhằm nâng cao chất lượng, an toàn cho hệ
                  thống thiết bị kỹ thuật và giá trị cuối cùng là mang lại sự ổn
                  định, an toàn và chi phí hợp lý.
                </div>
              </div>
            </div>
          </section>
        </div>
        <Menu
          anchorEl={sortRef.current}
          className={"menu-box"}
          onClose={handleSortClose}
          open={openSort}
          // elevation={1}
          style={{ width: 400 }}
        >
          {[
            {
              title: "Quản lý, vận hành BĐS",
              href: "#section-1",
            },
            {
              title: "Vệ sinh công nghiệp",
              href: "#section-2",
            },
            {
              title: "Chăm sóc cảnh quan",
              href: "#section-3",
            },
            {
              title: "Bảo trì, bảo dưỡng",
              href: "#section-4",
            },
          ].map((option) => (
            <MenuItem key={option} onClick={() => handleSortSelect(option)}>
              <a
                className="title-mb"
                style={{ textDecoration: "none", width: withView }}
                href={option.href}
              >
                {option.title}
              </a>
            </MenuItem>
          ))}
        </Menu>
      </header>
    </Page>
  );
}

export default ServiceView;
