import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import accountReducer from "./accountReducer";
import notificationsReducer from "./notificationsReducer";
import chatReducer from "./chatReducer";
import mailReducer from "./mailReducer";
import kanbanReducer from "./kanbanReducer";
import settingReducer from "./settingReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  setting: settingReducer,
  form: formReducer,
});

export default rootReducer;
