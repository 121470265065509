import React, { useEffect, useState, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  Typography,
  Link,
  makeStyles,
  MenuItem,
  IconButton,
  Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { getInfomationRequest } from "src/actions/infomationActions";
import { useDispatch } from "react-redux";
import { APP_VERSION } from "src/config";
import Logo from "src/components/Logo";
import LogoFooter from "src/assets/images/logo_footer.png";
import iconfb from "src/assets/images/iconfb.png";
import iconig from "src/assets/images/iconig.png";
import "./Footer.css";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  toolbar: {
    height: 80,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

function Footer(props) {
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();
  const dispatch = useDispatch();

  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [dataFooter, setDataFooter] = useState("");

  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    getProjects();
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const getProjects = useCallback(() => {
    dispatch(
      getInfomationRequest(
        (res) => {
          console.log("getInfomationRequest", res);
          setDataFooter(res);
        },
        (error) => {
          console.log("error", error);
        }
      )
    );
  }, []);

  const displayMobile = () => {
    return (
      <div class="navigation">
        <input type="checkbox" class="navigation__checkbox" id="navi-toggle" />
        <label for="navi-toggle" class="navigation__button">
          <span class="navigation__icon">&nbsp;</span>
        </label>
        <div class="navigation__background">&nbsp;</div>
        <nav class="navigation__nav">
          <ul class="navigation__list">
            <li class="navigation__item">
              <a href="#" class="navigation__link">
                Trung Chủ
              </a>
            </li>
            <li class="navigation__item">
              <a href="#" class="navigation__link">
                Về Nam Saigon
              </a>
            </li>
            <li class="navigation__item">
              <a href="#" class="navigation__link">
                Dịch vụ
              </a>
            </li>
            <li class="navigation__item">
              <a href="#" class="navigation__link">
                Dự án
              </a>
            </li>
            <li class="navigation__item">
              <a href="#" class="navigation__link">
                Tin tức
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  };
  return (
    <div>
      {!mobileView ? (
        <div className="footer-view">
          <div className="row"></div>
          <div
            className="view-top-footer row-custom"
            style={{
              textAlign: "right",
            }}
          >
            <div style={{ display: "flex", flexGrow: 1 }}>
              <img
                style={{
                  width: 174,
                  height: 153,
                  marginTop: -32,
                  //   position: "absolute",
                  //   top: -14,
                  marginLeft: 24,
                }}
                src={LogoFooter}
              />
            </div>
            <div style={{ marginRight: -16, marginBottom: 67, marginTop: 59 }}>
              {props.headersData.map((item) => {
                return (
                  <Link
                    className={"link-item-footer"}
                    component={RouterLink}
                    to={item.href}
                  >
                    {item.label}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="row-custom" style={{ marginTop: 32 }}>
            <div style={{ flexGrow: 1, display: "flex" }}>
              <div
                className="col-md-6"
                style={{ paddingLeft: 0, paddingRight: 40 }}
              >
                <div className="col-md-9 title-bottom">
                  Địa chỉ: {dataFooter.address} <br />
                  {dataFooter.office !== ""
                    ? "Văn phòng đại diện: " + dataFooter.office
                    : ""}
                </div>
              </div>
              <div className="col-md-3">
                <div style={{ marginBottom: 16 }}>
                  <img
                    className="hover-ct"
                    src={iconfb}
                    onClick={() => {
                      const win = window.open(dataFooter.fb, "_blank");
                      win.focus();
                    }}
                  />
                  <img
                    className="hover-ct"
                    style={{ marginLeft: 16 }}
                    src={iconig}
                    onClick={() => {
                      const win = window.open(dataFooter.ig, "_blank");
                      win.focus();
                    }}
                  />
                </div>
                <div className="title-bottom">
                  Hotline: {dataFooter.hotline}
                </div>
                <div className="title-bottom" style={{ marginTop: 8 }}>
                  Điện thoại: {dataFooter.phone}
                </div>
                <div className="title-bottom" style={{ marginTop: 8 }}>
                  Email: {dataFooter.email}
                </div>
              </div>
            </div>
            <div className="">
              <div>
                <div className="title-bottom">
                  CÔNG TY CỔ PHẦN QUẢN LÝ BẤT ĐỘNG SẢN NAM SÀI GÒN
                </div>
                <div className="title-bottom">
                  © 2012 - 2021. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="footer-view">
          <div className="row"></div>
          <div
            className="view-top-footer row-custom"
            style={{
              textAlign: "right",
            }}
          >
            <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
              <img
                style={{
                  width: 174,
                  height: 153,
                  marginTop: -48,
                }}
                src={LogoFooter}
              />
            </div>
          </div>
          <div style={{ marginTop: 32 }}>
            <div>
              <div
                className="col-md-6"
                style={{ paddingLeft: 0, paddingRight: 40 }}
              >
                <div className="col-md-12 title-bottom">
                  {dataFooter.address} <br /> {dataFooter.office}
                </div>
              </div>
              <div>
                <div style={{ marginBottom: 16, marginTop: 32 }}>
                  <img
                    className="hover-ct"
                    src={iconfb}
                    onClick={() => {
                      const win = window.open(dataFooter.fb, "_blank");
                      win.focus();
                    }}
                  />
                  <img
                    className="hover-ct"
                    style={{ marginLeft: 16 }}
                    src={iconig}
                    onClick={() => {
                      const win = window.open(dataFooter.ig, "_blank");
                      win.focus();
                    }}
                  />
                </div>
                <div className="title-bottom">
                  Hotline: {dataFooter.hotline}
                </div>
                <div className="title-bottom" style={{ marginTop: 8 }}>
                  Điện thoại: {dataFooter.phone}
                </div>
                <div className="title-bottom" style={{ marginTop: 8 }}>
                  Email: {dataFooter.email}
                </div>
              </div>
            </div>
            <div style={{ marginTop: 32 }}>
              <div>
                <div className="title-bottom">
                  CÔNG TY CỔ PHẦN QUẢN LÝ BẤT ĐỘNG SẢN NAM SÀI GÒN
                </div>
                <div className="title-bottom">
                  © 2012 - 2021. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
